import DefaultSpinner from '@library/ui/spinner/DefaultSpinner';
import classNames from 'classnames';
import React, { ReactNode, forwardRef, useRef } from 'react';



import styles from './ModalBody.module.scss';

interface IProps {
  children: ReactNode
  className?: string
  isLoading?: boolean
}

const ModalBody = forwardRef<HTMLDivElement, IProps>(
  ({ children, className, isLoading = false }, forwardedRef) => {
    const defaultRef = useRef<HTMLDivElement>(null);
    const ref = forwardedRef || defaultRef;

    return (
      <div
        ref={ref}
        className={classNames(
          'modal-body',
          styles.modalBody,
          isLoading && styles.modalBodyLoading,
          className,
        )}
      >
        {isLoading ? <DefaultSpinner /> : children}
      </div>
    );
  },
);

export default ModalBody;
