

import { personService } from '@services';
import { PersonCardForList } from '@services/models/person';
import { useLoader } from '@src/library/utils/hooks';
import { useState } from 'react';

type PersonsFilterItem = {
  key: string
  text: string
  value: string
}

export const usePersonsFilter = () => {
  const [personsList, setPersonsList] = useState<PersonCardForList[]>([]);
  const [mappedPersons, setMappedPersons] = useState<PersonsFilterItem[]>([]);

  const isPersonsLoading = useLoader(async () => {
    const response = await personService.fetch(undefined, undefined);

    if (response.isSuccess && response.data?.data) {
      const data = _.orderBy(response.data?.data, 'nickName', 'asc');
      setPersonsList(data);

      const mapped = data.map((user, index) => ({
        key: [user.id, user.nickName].join('_'),
        text: user.nickName!,
        value: user.nickName!,
      }));
      setMappedPersons(mapped);
    }
  }, []);

  return {
    personsList,
    mappedPersons,
    isPersonsLoading,
  };
};
