import { $catalog } from '@library/providers/StoreProvider';
import { IFeedbackStatus } from '@src/modules/catalog/model/catalogStore';
import { toJS } from 'mobx';
import { useMemo, useState } from 'react';



export const useStatusFilter = () => {
  const optionsStatus = useMemo(() => toJS($catalog.feedbackStatus), [$catalog.feedbackStatus]);
  const [statuses, setStatuses] = useState<IFeedbackStatus[]>([]);

  return {
    statuses,
    setStatuses,
    optionsStatus,
  };
};
