
import { PopoverAlignment } from '@carbon/react/lib/components/Popover';
import { $catalog, $loader } from '@library/providers/StoreProvider';
import EditStatus from '@library/ui/editStatus/EditStatus';
import { notificationsService } from '@services';
import { FeedbackItem } from '@services/models/notifications';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

type IProps = {
  item: FeedbackItem
  align?: PopoverAlignment
  disabled?: boolean
  silent?: boolean
}

const EditFeedbackStatus: FC<IProps> = ({
  item,
  align = 'bottom',
  disabled = false,
  silent = true,
}) => {
  const loaderName = silent ? 'edit-feedback-status-silent' : 'edit-feedback-status';

  const handleOnSelect = $loader.registerHandler(loaderName, async (x) => {
    const response = await notificationsService.updateFeedbackStatus({
      id: item.id!,
      code: x.originalId,
    });

    return response.isSuccess;
  });

  return (
    <EditStatus
      items={$catalog.feedbackStatus.map((x) => ({ value: x.value, text: x.shortName }))}
      value={item.status!}
      onChange={handleOnSelect}
      colorMapping={{
        open: 'cool-gray',
        closed: 'green',
      }}
      align={align}
      disabled={disabled}
    />
  );
};

export default observer(EditFeedbackStatus);
