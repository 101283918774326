import { Bounce, toast } from 'react-toastify';
import { ToastContent, ToastOptions } from 'react-toastify/dist/types';

export function showSuccessAlert<TData = unknown>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData>,
) {
  let customOptions: ToastOptions<TData> = {
    position: 'top-right',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    theme: 'light',
    transition: Bounce,
  };

  if (options) {
    customOptions = { ...customOptions, ...options };
  }

  toast.success(content, customOptions);
}

export function showErrorAlert<TData = unknown>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData>,
) {
  let customOptions: ToastOptions<TData> = {
    position: 'top-right',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    theme: 'light',
    transition: Bounce,
  };

  if (options) {
    customOptions = { ...customOptions, ...options };
  }

  toast.error(content, customOptions);
}
