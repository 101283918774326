
import { ParentChild } from '@carbon/icons-react';
import classNames from 'classnames';
import React, { FC } from 'react';

import { ExtendedPersonCardForList } from '../../model/PersonHierarchyModal.types';

import styles from './ChildCardsItem.module.scss';

type IProps = {
  data: ExtendedPersonCardForList
  onChangePerson: (nickName: string) => void
  className?: string
}

const ChildCardsItem: FC<IProps> = ({ data, onChangePerson, className }) => {
  const children = data.asFunctionalManager.length + data.asManager.length;

  return (
    <div
      className={classNames(styles.card, className)}
      onClick={() => onChangePerson(data.nickName!)}
    >
      <div>
        <div className={styles.title}>
          {data.nickName}
          <span>({data.name})</span>
        </div>
        {/*{data.jobPosition && <div className={styles.subTitle}>{data.jobPosition}</div>}*/}
      </div>
      {children > 0 && (
        <span className={styles.hasChildren}>
          <ParentChild />
          {data.asFunctionalManager.length + data.asManager.length}
        </span>
      )}
    </div>
  );
};

export default ChildCardsItem;
