
import { IQuarter, formatDate } from '@helpers/date';
import { $root as rootStore } from '@library/providers/StoreProvider';
import { makeAutoObservable, runInAction } from 'mobx';

import { asTree } from './appFilterHelpers';

export interface RenderTree {
  code: string
  name: string
  type?: 'person' | 'department'
  parent?: string
  children?: RenderTree[]
  data?: any
}

export class AppFilterStore {
  $root

  constructor(store: typeof rootStore) {
    makeAutoObservable(this);
    this.$root = store;
  }

  departments: any[] = []
  selected: string[] = []
  filterInitialized = false
  pepAnalyticsDate: string = formatDate(new Date(), { format: 'isoDate' })
  HCAnalyticsPeriods: IQuarter[] | [] = []

  SET_DEPARTMENTS(deps: any) {
    this.departments = deps;
  }

  SET_SELECTED_CODES(deps: string[]) {
    this.selected = deps;
  }

  SET_FILTER_INITIALIZED(value: boolean) {
    this.filterInitialized = value;
  }

  SET_PEP_ANALYTICS_DATE(date: Date) {
    this.pepAnalyticsDate = formatDate(date, { format: 'isoDate' });
  }

  SET_SELECTED_HC_PERIODS(quarters: IQuarter[]) {
    this.HCAnalyticsPeriods = quarters;
  }

  //TODO figure out why is it not cached
  get departmentTree(): RenderTree[] {
    return asTree(this.departments);
  }

  get selectedList() {
    return this.departments.filter((x) => this.selected.includes(x.code));
  }

  get selectedCodes() {
    return this.selectedList.map((x) => x.code);
  }

  removeDepartment(code: string) {
    if (code) {
      const idx = this.selected.findIndex((x) => x === code);
      this.selected.splice(idx, 1);
    }

    if (this.selected.length === 0) {
      this.selected = [];
    }
  }

  clear() {
    runInAction(() => {
      this.SET_FILTER_INITIALIZED(false);
      this.SET_DEPARTMENTS([]);
      this.SET_SELECTED_CODES([]);
      this.SET_PEP_ANALYTICS_DATE(new Date());
      this.SET_SELECTED_HC_PERIODS([]);
    });
  }
}
