import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';


import styles from './InlineTabFormWrapper.module.scss';

interface InlineTabFormWrapperProps {
  children?: ReactNode
  className?: string
}

const InlineTabFormWrapper: FC<InlineTabFormWrapperProps> = ({ children, className }) => {
  return <div className={classNames(styles.wrapper, className)}>{children}</div>;
};

export default InlineTabFormWrapper;
