
import { localStore } from '@helpers/other';
import { useAppAuth } from '@library/providers/AppAuthProvider';
import { $modal, $user } from '@library/providers/StoreProvider';
import ChooseRoleModal from '@library/providers/ui/ChooseRoleModal';
import HealthCheckFormModal from '@src/modules/healthcheck/ui/HealthCheckFormModal';
import NotificationBell from '@src/modules/notificationsBell/ui/NotificationsBell';
import OnboardingFormModal from '@src/modules/onboardings/ui/OnboardingFormModal';
import PersonalEvolutionPlanFormModal from '@src/modules/personalEvolutionPlan/ui/PersonalEvolutionPlanFormModal';
import TaskForm from '@src/modules/task/ui/TaskForm';
import React, { ReactNode } from 'react';

import styles from '../../ui/AppNavigation.module.scss';

export type MenuItem = {
  component?: ReactNode
  text?: string | ReactNode
  secondaryText?: string | ReactNode
  to?: string
  onClick?: Function
  condition?: () => boolean
  subItems?: MenuItem[]
  subItemsAlign?: 'left' | 'right'
}

export const useNavConfig = () => {
  const auth = useAppAuth();

  const mainMenuItems: MenuItem[] = [
    {
      text: 'Личный кабинет',
      to: '/user-profile/',
    },
    {
      text: 'Мои подразделения',
      to: '/departments/',
      condition: () =>
        $user.hasPerm(['PERM_MYDEPARTMENTS_READ', 'PERM_MYDEPARTMENTS_MANAGER_READ']),
    },
    {
      text: 'Мои сотрудники',
      to: '/employees/',
      condition: () => $user.hasPerm('PERM_MYEMPLOYEES_MANAGER_READ'),
    },
    {
      text: 'Аналитика по подразделениям',
      to: '/analytics/',
      condition: () =>
        $user.hasPerm([
          'PERM_ANALYTICS_IPR',
          'PERM_ANALYTICS_TASKS',
          'PERM_ANALYTICS_HEALTHCHECK',
          'PERM_ANALYTICS_ONBOARDING',
        ]),
    },
    {
      text: 'Справочные данные',
      subItems: [
        {
          text: 'Структура компании',
          to: '/company-structure/',
        },
        {
          text: 'Профили компетенций',
          to: '/job-profiles/',
          condition: () => $user.hasPerm('PERM_JOBPROFILES'),
        },
      ],
    },
    {
      text: 'Ввод данных',
      subItems: [
        {
          text: 'Создать Health Check',
          onClick: () => $modal.add(HealthCheckFormModal, { multi: true }),
          condition: () => $user.hasPerm('PERM_HEALTHCHECK_FULL'),
        },
        {
          text: 'Создать задачу',
          onClick: () => $modal.add(TaskForm, { multi: true }),
          condition: () => $user.hasPerm('PERM_TASK_ADD'),
        },
        {
          text: 'Добавить адаптацию',
          onClick: () => $modal.add(OnboardingFormModal),
          condition: () => $user.hasPerm('PERM_ONBOARDING_ADD'),
        },
        {
          text: 'Добавить ИПР',
          onClick: () => $modal.add(PersonalEvolutionPlanFormModal, { allowPersonSelect: true }),
          condition: () => $user.hasPerm('PERM_IPR_ADD'),
        },
      ],
      condition: () =>
        $user.hasPerm([
          'PERM_HEALTHCHECK_FULL',
          'PERM_TASK_ADD',
          'PERM_ONBOARDING_ADD',
          'PERM_IPR_ADD',
        ]),
    },
    {
      text: 'Администрирование',
      to: '/administration/',
      condition: () => $user.hasPerm('PERM_USER_ROLE_ADD'),
    },
  ];

  const secondMenuItems: MenuItem[] = [
    {
      component: <NotificationBell className={styles.navLink} />,
      condition: () => $user.hasPerm('PERM_NOTIFICATIONS'),
    },
    {
      text: $user.loggedInUser.preferredUsername,
      secondaryText: <div className={styles.secondLine}>{$user.roleGroup}</div>,
      subItemsAlign: 'right',
      subItems: [
        {
          text: 'Сменить роль',
          onClick: () =>
            $modal.add(ChooseRoleModal, { roles: $user.availableRoles, isChange: true }),
          condition: () => $user.availableRoles?.length > 1,
        },
        {
          text: 'Профиль',
          to: '/profile/',
          condition: () => false,
        },
        {
          text: 'Настройки уведомлений',
          to: '/profile/notifications/',
          condition: () => $user.hasPerm('PERM_NOTIFICATIONS'),
        },
        {
          text: 'Список обращений',
          to: '/feedback/',
        },
        {
          text: 'Выход',
          onClick: () => {
            localStore.clear();
            auth.logout();
          },
        },
      ],
    },
  ];

  return { mainMenuItems, secondMenuItems };
};
