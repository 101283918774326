

import { $filter, $loader, $user } from '@library/providers/StoreProvider';
import { $windowSize } from '@library/utils/windowSize';
import { catalogService } from '@services';
import { ICallback, ISubscribeType } from '@src/library/utils/loader/loaderStore';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { isAndroid, isIOS, isMacOs, isWindows } from 'react-device-detect';

interface PaginationProps<ST = string> {
  limit?: number
  defaultSortOrder?: 'asc' | 'desc'
  defaultSortBy?: ST
  dependencies?: any[]
}

export interface PaginationOptions<ST = string> {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  totalItems: number
  setPagingCount: Dispatch<SetStateAction<number>>
  handleHeaderClick: (headerKey: string) => void
  sortBy?: ST
  sortOrder?: 'asc' | 'desc'
  limit: number
  offset: number
  itemsShown: number | undefined
}

export const usePagination = <ST = string>({
  limit = 10,
  defaultSortBy,
  defaultSortOrder = 'asc',
  dependencies,
}: PaginationProps<ST> = {}): PaginationOptions<ST> => {
  const [page, setPage] = useState(0);
  const [pagingCount, setPagingCount] = useState(0);
  const [sortBy, setSortBy] = useState<ST | undefined>(defaultSortBy);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(defaultSortOrder);

  useEffect(() => {
    dependencies && dependencies?.length && setPage(0);
  }, dependencies);

  const handleHeaderClick = (headerKey?: string) => {
    if (sortBy === headerKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(headerKey as unknown as ST);
      setSortOrder('asc');
    }
  };

  return {
    page,
    setPage,
    totalItems: Math.ceil(pagingCount / limit!),
    setPagingCount,
    sortBy,
    sortOrder,
    handleHeaderClick,
    limit: limit!,
    offset: page * limit!,
    itemsShown: $windowSize.maxSM ? 5 : undefined,
  };
};

export const useDepartmentFilter = () => {
  const isLoading = useLoader(async () => {
    const response = await catalogService.fetchDepartments($user.manager, $user.manager);

    if (response.isSuccess && response.data) {
      $filter.SET_DEPARTMENTS(response.data[0].data);
    }
  });

  return isLoading;
};

export const useOnClickOutside = (callback: () => void, isObserve = true) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isObserve) {return;}

    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [isObserve]);

  return ref;
};

export const useClassNameByOS = () => {
  useEffect(() => {
    if (isWindows) {
      document.documentElement.classList.add('isWindows');
    } else if (isMacOs) {
      document.documentElement.classList.add('isMacOs');
    } else if (isIOS) {
      document.documentElement.classList.add('isIOS');
    } else if (isAndroid) {
      document.documentElement.classList.add('isAndroid');
    }
  }, []);
};

export const useFirstRender = () => {
  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};

export const useLoaderSubscribe = (
  eventId: string,
  callback: ICallback,
  callbackType?: ISubscribeType,
) => {
  useEffect(() => {
    const subIds = $loader.subscribe(eventId, callback, callbackType);

    return () => $loader.unsubscribe(eventId, subIds);
  }, []);
};

export const useLoader = (callback: () => Promise<void>, deps: any[] = []) => {
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isActive = true;

    const func = async () => {
      setIsLoading(true);
      await callback();

      if (isActive) {
        setIsLoading(false);
      }
    };

    func();

    return () => {
      isActive = false;
    };
  }, deps);

  return isLoading;
};

const useScrolledTo = (threshold = 0): void => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > threshold) {
        document.documentElement.classList.add(`scrolled-${threshold}`);
      } else {
        document.documentElement.classList.remove(`scrolled-${threshold}`);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [threshold]);
};

export default useScrolledTo;

export const useInnerValue = <T>(value: T) => {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    if (!_.isEqual(value, innerValue)) {
      setInnerValue(value);
    }
  }, [value]);

  return [innerValue, setInnerValue] as [T, React.Dispatch<React.SetStateAction<T>>];
};

export const useScrollIntoView = <T extends HTMLElement>() => {
  const ref = useRef<null | T>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [ref.current]);

  return ref;
};
