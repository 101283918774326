import { $root as rootStore } from '@library/providers/StoreProvider';
import { makeAutoObservable, runInAction } from 'mobx';


class GlobalStore {
  $root

  constructor(store: typeof rootStore) {
    makeAutoObservable(this);
    this.$root = store;
  }

  isReadyForInit = false

  SET_IS_READY_FOR_INIT(value: boolean) {
    runInAction(() => (this.isReadyForInit = value));
  }
}

export default GlobalStore;
