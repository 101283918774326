
import { Select, SelectItem } from '@carbon/react';
import { getInlineHealthCheckText } from '@helpers/text.js';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import { HEALTHCHECK_CAUSE } from '@library/utils/constants';
import { devPlanService } from '@services';
import { HealthCheck } from '@services/models/health-check';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { UseControllerProps, useController, useWatch } from 'react-hook-form';

interface IProps extends UseControllerProps {
  labelText: ReactNode
  readOnly: boolean
}

const HealthCheckDropdown = (props: IProps) => {
  const [healthCheckList, setHealthCheckList] = useState<HealthCheck[]>([]);
  const [loaded, setLoaded] = useState(false);

  const {
    field,
    fieldState: { invalid, error },
    formState: { defaultValues },
  } = useController(props);

  const values = useWatch({
    control: props.control,
  });

  const department = useMemo(() => {
    return values?.departmentObject.code
      ? values?.departmentObject.code
      : values?.personObject.department;
  }, [values?.personObject.nickName, values?.departmentObject.code]);

  async function loadHealthCheckList(department: string | undefined) {
    setLoaded(false);
    let resultList: HealthCheck[] = [];

    const departments = department ? [department] : undefined;

    const response = await devPlanService.fetch({ departments });

    if (response.isSuccess && response.data?.healthCheck) {
      resultList = [...response.data?.healthCheck].filter((x) => x.id);
    }

    // только для существующих задач: подгружаем единичный hc если он не найден по каким то причинам в общем списке
    if (
      defaultValues?.taskCause?.causeType === HEALTHCHECK_CAUSE &&
      defaultValues?.taskCause?.causeObjectId &&
      department === defaultValues?.departmentObject?.code //TODO could be person as well
    ) {
      const found = resultList.find((x) => x.id === defaultValues?.taskCause!.causeObjectId);

      if (!found) {
        const response = await devPlanService.get(defaultValues?.taskCause.causeObjectId!);

        if (response.isSuccess && response.data) {
          resultList.push(response.data);
        }
      }
    }

    // проверяем если значения нет в списке устанавливаем его в первое найденное
    const found = resultList.find((x) => x.id === field.value.causeObjectId);

    if (resultList.length && !found) {
      field.onChange(getValue(resultList, resultList[0]?.id!));
    }

    setHealthCheckList(resultList);
    setLoaded(true);
  }

  //загрузка при изменении department
  useEffect(() => {
    if (values?.taskCause.causeType === HEALTHCHECK_CAUSE) {
      if (department) {
        loadHealthCheckList(department);
      } else {
        setLoaded(true);
      }
    }
  }, [values?.taskCause.causeType]);

  function getTitle(x: HealthCheck) {
    return getInlineHealthCheckText(x);
  }

  function getValue(list: HealthCheck[], value: string) {
    const item = list.find((x) => x.id === value);

    return {
      causeObjectId: value,
      causeText: getTitle(item!),
      causeType: HEALTHCHECK_CAUSE,
    };
  }

  const onChange = (e: any) => {
    field.onChange(getValue(healthCheckList, e.target.value));
  };

  const options = useMemo(() => {
    if (healthCheckList?.length > 0) {
      return healthCheckList.map((hc) => ({ text: getTitle(hc), value: hc.id }));
    } else {
      return [{ text: 'нет HC по подразделению', value: '', disabled: true }];
    }
  }, [healthCheckList]);

  return (
    <WithLoaderWrapper isLoading={!loaded} size="small">
      <Select
        id={field.name}
        labelText={props.labelText}
        invalid={invalid}
        invalidText={error?.message}
        readOnly={props.readOnly}
        name={field.name}
        onChange={onChange}
        onBlur={field.onBlur}
        value={field.value.causeObjectId}
        ref={field.ref}
      >
        {options.map((x: any) => (
          <SelectItem key={x.value} text={x.text} value={x.value} disabled={x.disabled ?? false} />
        ))}
      </Select>
    </WithLoaderWrapper>
  );
};

export default HealthCheckDropdown;
