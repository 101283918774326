import classNames from 'classnames';
import React, { ReactNode } from 'react';


import styles from './ModalBackButton.module.scss';

interface IProps {
  children?: ReactNode
  className?: string
  onClick: () => void
}

const ModalBackButton: React.FC<IProps> = ({ children, className, onClick }) => {
  return (
    <div
      className={classNames('modal-back-button', styles.modalBackButton, className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default ModalBackButton;
