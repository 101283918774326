import { NumberInput } from '@carbon/react';
import React, { FC } from 'react';


type IndexFieldProps = {
  id: string
  label: string
  defaultValue: string
  setValue: Function
  readOnly?: boolean
  error: any
}
const integerRe = new RegExp(/[0-9]/);

const HealthCheckIndexField: FC<IndexFieldProps> = ({
  id,
  label,
  defaultValue = '',
  setValue: setFormValue,
  error,
  readOnly = false,
}) => {
  function onKeyDown(e: any) {
    const key = e.key;

    if (!integerRe.test(key) && key.length === 1) {
      e.preventDefault();
    }
  }

  return (
    <NumberInput
      id={id}
      label={`${label}, %`}
      readOnly={readOnly}
      onKeyDown={onKeyDown}
      allowEmpty={true}
      onChange={(e: any, { value: v, direction }) => {
        setFormValue(id, v);
      }}
      value={defaultValue}
      invalid={!!error}
      step={1}
      min={0}
      max={100}
      invalidText={<>{error?.message}</>}
     />
  );
};

export default HealthCheckIndexField;
