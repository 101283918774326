import emptyProfileImg from '@assets/images/profile-empty.png';
import { isUrl } from '@helpers/other';
import classNames from 'classnames';
import React, { FC } from 'react';



import styles from './PersonPhoto.module.scss';

type IProps = {
  src: string | undefined
  className?: string
}

const PersonPhoto: FC<IProps> = ({ src, className }) => {
  let preparedSrc = isUrl(src) ? src : 'data:image/(png|jpg);base64,' + src;

  return (
    <div className={classNames(styles.photo, className)}>
      {src ? (
        <img src={preparedSrc} className={styles.personPhoto} alt="" />
      ) : (
        <img src={emptyProfileImg} className={styles.placeHolder} alt="" />
      )}
    </div>
  );
};

export default PersonPhoto;
