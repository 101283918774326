
import { Button, ButtonSet, ModalFooter, Select, SelectItem, Stack } from '@carbon/react';
import { localStore } from '@helpers/other';
import { useAppAuth } from '@library/providers/AppAuthProvider';
import { $user } from '@library/providers/StoreProvider';
import Title from '@library/ui/title/Title';
import { PermissionsListType } from '@library/utils/constants';
import { ModalBase, ModalBody, ModalHeader } from '@library/utils/modals/index';
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store';
import { UserPermissions } from '@services/models/userPermissions';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  roles: UserPermissions[]
  _core: IModalItemCore
  isChange?: boolean
  mandatorySelection?: boolean
}

const ChooseRoleModal: FC<IProps> = ({
  roles,
  _core,
  isChange = false,
  mandatorySelection = false,
}) => {
  const [userRole, setUserRole] = useState<UserPermissions | null>(null);
  const navigate = useNavigate();

  const auth = useAppAuth();

  useEffect(() => {
    if (roles) {
      setUserRole(roles[0]);
    }
  }, [roles]);

  const handleChange = (value: string) => {
    const role = roles?.find((item) => item?.userRoleGroup === value || item?.userRole === value);
    !!role && setUserRole(role);
  };

  const handleLogout = () => {
    localStore.clear();
    auth.logout();
    _core.hide();
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault(); // cancel form submit to keep url params intact
    !!userRole &&
      $user.setUserData(
        userRole?.userRole,
        userRole.permissions as PermissionsListType[],
        (userRole.userRoleGroup as string) ?? (userRole?.userRole as string),
      );

    if (isChange) {
      navigate('/user-profile/');
    }

    _core.hide();
  };

  return (
    <ModalBase
      closeButton={!mandatorySelection}
      closeOnEsc={!mandatorySelection}
      closeOnOverlay={!mandatorySelection}
    >
      <ModalHeader>
        <Title size="h2">Выбор роли</Title>
      </ModalHeader>
      <ModalBody>
        <form id="roles-form" onSubmit={handleSubmit}>
          <Stack gap={6}>
            <Select id="role" labelText="Роль" onChange={(e) => handleChange(e?.target?.value)}>
              {roles?.map((role) =>
                role?.userRoleGroup ? (
                  <SelectItem
                    key={role?.userRoleGroup}
                    text={role?.userRoleGroup}
                    value={role?.userRoleGroup}
                  />
                ) : (
                  role?.userRole && (
                    <SelectItem key={role?.userRole} text={role?.userRole} value={role?.userRole} />
                  )
                ),
              )}
            </Select>
          </Stack>
        </form>
      </ModalBody>

      <ModalFooter>
        <ButtonSet>
          {!mandatorySelection && (
            <Button kind="tertiary" onClick={isChange ? _core.hide : handleLogout}>
              Отмена
            </Button>
          )}
          <Button form="roles-form" type="submit">
            Выбрать
          </Button>
        </ButtonSet>
      </ModalFooter>
    </ModalBase>
  );
};

export default observer(ChooseRoleModal);
