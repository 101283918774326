
import { formErrors } from '@library/utils/constants';
import { HealthCheckIndexCodeEnum } from '@services/models/health-check';
import { z } from 'zod';

const indexSchema = z.object({
  code: z.nativeEnum(HealthCheckIndexCodeEnum),
  value: z.preprocess((x) => (!x ? 0 : x), z.number().min(0).max(100)),
});

const schema = z.object({
  author: z.string().optional(),
  department: z.string({
    errorMap: (issue, { defaultError }) => ({
      message: issue.code === 'invalid_type' ? 'Поле обязательно' : defaultError,
    }),
  }),
  createDate: z.coerce.date().optional(),
  startDate: z.coerce.date({
    errorMap: (issue, { defaultError }) => ({
      message: issue.code === 'invalid_date' ? formErrors.invalidValue : defaultError,
    }),
  }),
  indexList: z
    .array(indexSchema, {
      errorMap: (issue, { defaultError }) => ({
        message:
          issue.code === 'invalid_type'
            ? 'Требуется хотя бы один не нулевой показатель'
            : defaultError,
      }),
    })
    .refine(
      (indexes) => {
        const total = indexes.reduce((total, idx) => {
          total += idx.value;

          return total;
        }, 0);

        return total > 0;
      },
      { message: 'Требуется хотя бы один не нулевой показатель' },
    ),
  reason: z.string(),
  link: z.preprocess(
    (x: any) => (!x.linkText && !x.linkDescription ? undefined : x),
    z
      .object({
        linkText: z.string().url({ message: formErrors.invalidUrl }),
        linkDescription: z.string().optional(),
      })
      .optional(),
  ),
});
export default schema;

export type HealthCheckFormInputs = z.infer<typeof schema>
