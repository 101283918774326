
import { ComposedModal, ModalBody, ModalFooter, ModalHeader, Stack, TextInput } from '@carbon/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { formErrors } from '@library/utils/constants';
import { authService, tokenService } from '@services';
import { getConfig } from '@services/config';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

const LOGIN_FORM = 'login-form';

const schema = z.object({
  login: z.string().min(1, { message: formErrors.required }),
  password: z.string().min(1, { message: formErrors.required }),
});

const LoginPage = () => {
  const appConfig = getConfig();
  const nav = useNavigate();

  useEffect(() => {
    if (tokenService.authenticated || appConfig.useOidc) {
      nav('/');
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { login: '', password: '' },
    resolver: zodResolver(schema),
  });

  const values = watch();

  async function doLogin() {
    const response = await authService.login(values.login, values.password);

    if (response.isSuccess) {
      await tokenService.setUser(response.data);
      nav('/');
    }
  }

  function onKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      doLogin();
    }
  }

  return (
    <ComposedModal
      open={true}
      size="xs"
      onKeyDown={onKeyDown}
      preventCloseOnClickOutside={true}
      onClose={() => false}
    >
      <ModalHeader title="Вход" closeClassName="no-display" />
      <ModalBody hasForm={true}>
        <form id={LOGIN_FORM} noValidate={true} onSubmit={handleSubmit(doLogin)} autoComplete="off">
          <Stack gap={6}>
            <TextInput
              type="text"
              labelText="Ник"
              id="login"
              {...register('login')}
              invalid={!!errors.login}
              invalidText={<>{errors.login?.message}</>}
            />
            <TextInput
              type="password"
              labelText="Пароль"
              id="password"
              {...register('password')}
              invalid={!!errors.password}
              invalidText={<>{errors.password?.message}</>}
            />
          </Stack>
        </form>
      </ModalBody>
      <ModalFooter primaryButtonText="Вход" onRequestSubmit={handleSubmit(doLogin)}>
        <></>
      </ModalFooter>
    </ComposedModal>
  );
};

export default observer(LoginPage);
