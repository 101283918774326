
import { TextArea } from '@carbon/react';
import { TextAreaProps } from '@carbon/react/lib/components/TextArea/TextArea';
import React, { FC, useEffect, useImperativeHandle, useRef } from 'react';

type IProps = TextAreaProps

const AutoSizedTextArea: FC<IProps> = React.forwardRef((props, outerRef) => {
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(outerRef, () => innerRef.current, []);

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.style.height = innerRef.current.scrollHeight + 'px';
    }
  }, [innerRef.current?.value]);

  return <TextArea ref={innerRef} rows={1} {...props} />;
});

export default AutoSizedTextArea;
