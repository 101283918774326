import { PersonCardForList } from '@src/services/models/person';
import React, { FC, useMemo } from 'react';
import { Mention, MentionsInput } from 'react-mentions';


import styles from './Mentions.module.scss';

type Props = {
  value: string
  onChange: (val: string) => void
  onFocus?: () => void
  placeholder?: string
  persons?: PersonCardForList[]
  mentions: string[]
  setMentions: (val: string[]) => void
  id: string
  enableCounter?: boolean
  maxCount?: number
  invalid?: boolean
  invalidText?: string
  labelText?: string
  disabled?: boolean
}

const MentionsTextarea: FC<Props> = ({
  value,
  onChange,
  onFocus,
  placeholder,
  persons,
  setMentions,
  mentions,
  id,
  labelText,
  invalid,
  invalidText,
  disabled,
  enableCounter,
  maxCount,
}) => {
  const personsOptions = useMemo(
    () => persons?.map((item) => ({ id: item?.nickName || '', display: item?.nickName || '' })),
    [persons],
  );

  return personsOptions ? (
    <>
      <div className={styles.container}>
        {labelText && (
          <div className="cds--text-area__label-wrapper">
            <label htmlFor={id} className="cds--label">
              {labelText}
            </label>
            {enableCounter && (
              <div className="cds--label">
                {value.length}/{maxCount}
              </div>
            )}
          </div>
        )}

        <MentionsInput
          id={id}
          value={value}
          onChange={(e) => onChange(e?.target?.value)}
          onFocus={onFocus}
          placeholder={placeholder}
          className={`mentions ${disabled ? 'mentions--disabled' : ''}`}
          classNames={styles}
          disabled={disabled}
          allowSuggestionsAboveCursor={true}
        >
          <Mention
            trigger="@"
            data={personsOptions}
            className={styles.mentions__mention}
            onAdd={(id, display: string) => setMentions([...mentions, display])}
          />
        </MentionsInput>

        {invalid && <div className={styles.mentions__error}>{invalidText}</div>}
      </div>
    </>
  ) : (
    <></>
  );
};

export default MentionsTextarea;
