
import ModalBackButton from '@library/utils/modals/ui/ModalBackButton';
import ModalCloseButton from '@library/utils/modals/ui/ModalCloseButton';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useContext, useEffect, useRef } from 'react';

import { ModalViewerContext } from '../ModalViewer.context';
import modalStore, { IModalItemCore } from '../ModalViewer.store';

import styles from './ModalBase.module.scss';

export interface IModalBaseProps {
  closeButton?: boolean
  closeOnOverlay?: boolean
  closeOnEsc?: boolean
  centered?: boolean
  scrollable?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  className?: string
  children: ReactNode
  onShow?: Function
  onHide?: Function
  backButton?: boolean
  onBack?: () => void
}

const ModalBase: React.FC<IModalBaseProps> = ({
  closeButton = true,
  closeOnOverlay = true,
  closeOnEsc = true,
  centered = true,
  scrollable = true,
  size = 'md',
  className,
  children,
  onShow,
  onHide,
  backButton = true,
  onBack,
}) => {
  const _core = useContext(ModalViewerContext) as IModalItemCore;
  const prevValueIsVisible = useRef(_core.isVisible);

  useEffect(() => {
    if (_.isFunction(onShow)) {
      _core.onShow = onShow;
    }

    if (_.isFunction(onHide)) {
      _core.onHide = onHide;
    }
  }, [onShow, onHide]);

  useEffect(() => {
    _core.show();
    document.documentElement.classList.add('modal-open');
  }, []);

  async function handleHide() {
    _core.hide();
  }

  useEffect(() => {
    if (prevValueIsVisible.current !== _core.isVisible && !_core.isVisible) {
      setTimeout(() => {
        modalStore.remove(_core.id);

        if (!modalStore.current.length) {
          document.documentElement.classList.remove('modal-open');
        }
      }, 300);
    }

    prevValueIsVisible.current = _core.isVisible;
  }, [_core.isVisible]);

  useEffect(() => {
    const handleOnEsc = async (e: KeyboardEvent) => {
      let lastModal = modalStore.current.slice(-1)[0];
      const isCurrentModal = _core?.id === lastModal?._core?.id;

      if (e.key === 'Escape' && isCurrentModal) {
        await handleHide();
      }
    };

    if (closeOnEsc) {
      window.addEventListener('keydown', handleOnEsc);
    }

    return () => window.removeEventListener('keydown', handleOnEsc);
  }, []);

  return (
    <div className={classNames(styles.modal, className)}>
      <div
        className={classNames(
          'modal-dialog',
          styles.modalDialog,
          size && styles['modalDialog' + size],
          centered && styles.modalDialogCentered,
          scrollable && styles.modalDialogScrollable,
        )}
      >
        <div
          className={classNames(
            'modal-overlay',
            styles.modalOverlay,
            _core.isVisible && styles.modalOverlayActive,
          )}
          onClick={async () => closeOnOverlay && (await handleHide())}
        />
        <div
          className={classNames(
            'modal-content',
            styles.modalContent,
            _core.isVisible && styles.modalContentActive,
          )}
        >
          {children}
          {backButton && onBack && <ModalBackButton onClick={onBack} />}
          {closeButton && <ModalCloseButton onClick={handleHide} />}
        </div>
      </div>
    </div>
  );
};

export default observer(ModalBase);
