
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { ModalViewerContext } from './ModalViewer.context';
import './ModalViewer.scss';
import modalStore, { IModalItem } from './ModalViewer.store';

const ModalViewer = () => {
  let modals: Array<{ component: any; item: IModalItem }> = [];

  _.forEach(modalStore.current, (item) => {
    const template = item._core.template;

    if (_.isObject(template)) {
      modals.push({ component: template, item });
    } else {
      console.error('The template "' + template + '" is missing.');

      return;
    }
  });

  return (
    <div className="modal-root">
      {modals.map((modal: any) => {
        const Component = modal.component;

        return (
          <ModalViewerContext.Provider key={modal.item._core.id} value={modal.item._core}>
            <Component {...modal.item} />
          </ModalViewerContext.Provider>
        );
      })}
    </div>
  );
};

export default observer(ModalViewer);
