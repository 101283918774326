import DefaultSpinner from '@library/ui/spinner/DefaultSpinner';
import classNames from 'classnames';
import * as React from 'react';
import { FC, ReactNode } from 'react';



import styles from './LayoutLkPageWrapper.module.scss';

interface Props {
  isLoading?: boolean
  children?: ReactNode
  className?: any
}

const LayoutLkPageWrapper: FC<Props> = ({ isLoading = false, children, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {!isLoading ? (
        <div>
          {children ? (
            children
          ) : (
            <div className={classNames(styles.notFound)}>Ничего не найдено</div>
          )}
        </div>
      ) : (
        <div className={classNames(styles.loader)}>
          <DefaultSpinner />
        </div>
      )}
    </div>
  );
};

export default LayoutLkPageWrapper;
