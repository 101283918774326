import classNames from 'classnames';
import React, { ReactNode } from 'react';


import styles from './ModalCloseButton.module.scss';

interface IProps {
  children?: ReactNode
  className?: string
  onClick: () => void
}

const ModalCloseButton: React.FC<IProps> = ({ children, className, onClick }) => {
  return (
    <div
      className={classNames('modal-close-button', styles.modalCloseButton, className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default ModalCloseButton;
