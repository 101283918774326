import dayjs from 'dayjs';
import { useState } from 'react';


export const useDayJsPeriodFilter = () => {
  const currentDate = dayjs();
  const [startDate, setStartDate] = useState(currentDate.subtract(3, 'month'));
  const [endDate, setEndDate] = useState(currentDate);

  const handleStartDateChange = (dates: any) => {
    setStartDate(dayjs(dates[0]));
  };

  const handleEndDateChange = (dates: any) => {
    setEndDate(dayjs(dates[0]));
  };

  const resetDates = () => {
    setStartDate(currentDate.subtract(3, 'month'));
    setEndDate(currentDate);
  };

  return {
    startDate,
    setStartDate: (dates: any) => handleStartDateChange(dates),
    endDate,
    setEndDate: (dates: any) => handleEndDateChange(dates),
    resetDates,
  };
};
