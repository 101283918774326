import { apiRequest } from '@services/api';
import { AppConfig } from '@services/config';
import {
  FeedbackFile,
  FeedbackFilter,
  FeedbackItem,
  FeedbackList,
  FeedbackNewStatus,
  Setting,
} from '@services/models/notifications';

class NotificationsService {
  baseUrl: string

  constructor(config: AppConfig) {
    this.baseUrl = config.notificationsServiceUrl;
  }

  async fetch() {
    return apiRequest({
      url: this.baseUrl + '/notification_settings',
      method: 'GET',
    });
  }

  async update(params: { data: Setting[] }) {
    return apiRequest({
      url: this.baseUrl + '/notification_settings',
      data: {
        data: params.data,
      },
      method: 'POST',
    });
  }

  async getNotifCount() {
    return apiRequest({
      url: this.baseUrl + '/hcm_notifications/count',
      method: 'GET',
    });
  }

  async getNotifList(params: { offset: number; limit: number }) {
    return apiRequest({
      url: this.baseUrl + '/hcm_notifications',
      method: 'GET',
      data: params,
    });
  }

  async markreadNotif(notificationsId: string[]) {
    return apiRequest({
      url: this.baseUrl + '/hcm_notifications/markread',
      data: { notificationsId },
      method: 'POST',
    });
  }

  async deleteNotif(notificationId: string) {
    return apiRequest({
      url: this.baseUrl + `/hcm_notifications/${notificationId}`,
      method: 'DELETE',
    });
  }

  async deleteAllNotif() {
    return apiRequest({
      url: this.baseUrl + `/hcm_notifications/all`,
      method: 'DELETE',
    });
  }

  async addFeedback(params: FormData) {
    return apiRequest<FeedbackItem>({
      method: 'POST',
      url: this.baseUrl + `/feedback`,
      data: params,
    });
  }

  async updateFeedbackStatus(params: { id: string; code: string }) {
    return apiRequest<FeedbackNewStatus>({
      method: 'POST',
      url: this.baseUrl + `/feedback/${params.id}/status`,
      data: params,
    });
  }

  async getFeedbackList(
    filters: FeedbackFilter,
    params: {
      limit: number
      offset: number
      sortBy?: Extract<
        keyof FeedbackItem,
        'id' | 'type' | 'personNickName' | 'author' | 'createDate' | 'status'
      >
      sortOrder?: 'asc' | 'desc'
    },
  ) {
    return apiRequest<FeedbackList>({
      method: 'POST',
      url: this.baseUrl + '/feedback_filtered',
      data: filters,
      params,
    });
  }

  async downloadFeedbackFile(feedbackId: string, fileId: string) {
    return apiRequest<FeedbackFile>({
      method: 'GET',
      url: this.baseUrl + `/feedback/${feedbackId}/files/${fileId}`,
    });
  }
}

export default NotificationsService;
