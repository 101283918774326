


import { $user } from '@library/providers/StoreProvider';
import PageTabs, { PageTabItem } from '@library/ui/pageTabs/PageTabs';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import { useDepartmentFilter } from '@src/library/utils/hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet } from 'react-router-dom';

const routesConfig: PageTabItem[] = [
  {
    label: 'ИПР',
    route: 'peps',
    condition: () => $user.hasPerm('PERM_ANALYTICS_IPR'),
  },
  {
    label: 'Задачи',
    route: 'tasks',
    condition: () => $user.hasPerm('PERM_ANALYTICS_TASKS'),
  },
  {
    label: 'Health Check',
    route: 'healthchecks',
    condition: () => $user.hasPerm('PERM_ANALYTICS_HEALTHCHECK'),
  },
  {
    label: 'Адаптация сотрудников',
    route: 'onboardings',
    condition: () => $user.hasPerm('PERM_ANALYTICS_ONBOARDING'),
  },
];

export function getAvailableAnalyticTabs() {
  return routesConfig.filter((x) => !x.condition || x.condition());
}

const AnalyticsPage = () => {
  const isLoading = useDepartmentFilter();

  return (
    <>
      <PageTabs tabs={getAvailableAnalyticTabs()} />
      <WithLoaderWrapper isLoading={isLoading}>
        <Outlet />
      </WithLoaderWrapper>
    </>
  );
};

export default observer(AnalyticsPage);
