
import { FormLabel, PaginationNav, Tag } from '@carbon/react';
import { formatDate, formatPeriod } from '@helpers/date';
import LayoutLkPageWrapper from '@library/layouts/LayoutLkPageWrapper';
import { useStore } from '@library/providers/StoreProvider';
import Accordion from '@library/ui/accordion/Accordion';
import { devPlanService } from '@services';
import { useLoader, usePagination } from '@src/library/utils/hooks';
import GPAccordionItem from '@src/pages/cabinet/modules/goalsPerformance/ui/GPAccordionItem';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import * as React from 'react';

import styles from './GoalsPerformancePage.module.scss';

const GoalsPerformancePage = ({ person: _person }: { person?: any }) => {
  const { $cabinet } = useStore();
  const [data, setData] = useState([]);
  const person = _person || $cabinet.data;

  const { page, setPage, totalItems, setPagingCount, limit, offset } = usePagination();

  const isLoading = useLoader(async () => {
    if (!$cabinet.canSeeGoalsPerformance) {
      return;
    }

    const response = await devPlanService.fetchPlanPerformance({
      personNickName: person.nickName,
      startDate: person.startDate,
      offset,
      limit,
    });

    if (response.isSuccess) {
      setData(response.data.data);
      setPagingCount(response.data.paging.count);
    }
  }, [person, offset, limit]);

  const preparedItems = useMemo(() => {
    let prepared = _.map(data, (x: any) => {
      const startsFrom = formatDate(x.startsFrom, { format: 'YYYY-MM-DD' });
      const dueTo = formatDate(x.dueTo, { format: 'YYYY-MM-DD' });
      const period = formatPeriod(x.startsFrom, x.dueTo);

      return {
        ...x,
        startsFrom,
        dueTo,
        period,
      };
    });
    prepared = _.orderBy(prepared, 'dueTo', 'desc');

    return prepared;
  }, [data]);

  return (
    <LayoutLkPageWrapper isLoading={isLoading}>
      <Accordion
        items={preparedItems.map((x: any) => {
          return {
            title: (
              <div className={classnames(styles.gpaTitle, 'row')}>
                <div>
                  <FormLabel>Период:</FormLabel>
                  <span className={styles.period}>{x.period}</span>
                </div>
                <div>
                  <FormLabel>Целей:</FormLabel> {x.goals.length}
                </div>
                <div>
                  <FormLabel>Статус:</FormLabel> <Tag type="outline">{x.planState}</Tag>
                </div>
                <div>
                  {x.performanceReview?.totalRate && (
                    <>
                      <FormLabel>Оценка результативности:</FormLabel>
                      <span className={styles.performanceReview}>
                        {x.performanceReview?.totalRate} <br />(
                        {x.performanceReview?.totalRateHumanized})
                      </span>
                    </>
                  )}
                </div>
                <div>
                  {x.performanceReview?.assesmentDate && (
                    <>
                      <FormLabel>Дата проведения оценки:</FormLabel>
                      <span className={styles.performanceReview}>
                        {formatDate(x.performanceReview?.assesmentDate)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ),
            content: (
              <GPAccordionItem
                goals={x.goals}
                planUrl={x.planUrl}
                performanceUrl={x.performanceReview?.performanceUrl}
              />
            ),
          };
        })}
        allowMultiple={true}
        initActiveIndex={0}
        className={styles.wrapper}
      />
      <PaginationNav
        className="pagination"
        page={page}
        totalItems={totalItems}
        onChange={setPage}
      />
    </LayoutLkPageWrapper>
  );
};

export default GoalsPerformancePage;
