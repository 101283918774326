import React, { FC, ReactNode } from 'react';

import { $root } from '../lib/rootStore';
import { RootStoreContext } from '../lib/StoreContext';

interface StoreProviderProps {
  children: ReactNode
}

export const StoreProvider: FC<StoreProviderProps> = ({ children }) => {
  return <RootStoreContext.Provider value={$root}>{children}</RootStoreContext.Provider>;
};
