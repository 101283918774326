import { DepartmentStats } from '../types/pep.types';

export const prcntTooltip = {
  callbacks: {
    label: (value: any) => {
      return `${Math.round(value.raw)}%`;
    },
  },
};

export const prcntTicks = {
  beginAtZero: true,
  callback: (value: any) => `${Math.round(value)}%`,
};

export const labelFormatter = (x: DepartmentStats) => {
  const department = x.department!;

  return department.length > 20 ? department.split(/[\s\\.]/) : department;
};

export const wrapLabel = (label: string, maxLineLength = 20) => {
  let words = _.replace(label, '.', '. ').split(' ');
  let lines: string[] = [];
  let currentLine = words[0];

  words.forEach((word, index) => {
    if (index === 0) {return;}

    if (currentLine.length + word.length + 1 <= maxLineLength) {
      currentLine += ' ' + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  });

  lines.push(currentLine);

  return lines;
};

export const integerTicks = {
  callback: (value: any) => {
    return value === Math.floor(value) ? value : undefined;
  },
};

export const emptyPlaceHolderPlugin = [
  {
    id: 'empty-placeholder',
    beforeDraw (chart: any) {
      const total = chart.data.datasets.reduce((acc: number, x: any) => {
        acc += x.data.length;

        return acc;
      }, 0);

      if (total === 0) {
        chart.scales.y.ticks = [];
      }
    },
    afterDraw (chart: any) {
      const total = chart.data.datasets.reduce((acc: number, x: any) => {
        acc += x.data.length;

        return acc;
      }, 0);

      if (total === 0) {
        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.height;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '30px Arial';
        ctx.fillStyle = 'rgb(224, 224, 224)';
        ctx.fillText('Нет данных', width / 2, height / 2);
        ctx.restore();
      }
    },
  },
];
