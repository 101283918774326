import { useState } from 'react';

type IOption = {
  text: string
  value: object
}

export const useOverduesFilter = () => {
  const options = [
    {
      id: 1,
      text: 'Непросроченные',
      value: { overdueTo: 0 },
    },
    {
      id: 2,
      text: 'До 7 дней',
      value: { overdueFrom: 1, overdueTo: 6 },
    },
    {
      id: 3,
      text: 'От 7 до 14 дней',
      value: { overdueFrom: 7, overdueTo: 13 },
    },
    {
      id: 4,
      text: 'Более 14 дней',
      value: { overdueFrom: 14 },
    },
  ];

  const [overdues, setOverdues] = useState<IOption[]>(options);

  return {
    options,
    overdues,
    setOverdues,
  };
};
