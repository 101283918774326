import React, { FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import type { ChartOptions } from 'chart.js';
import 'chart.js/auto';

import Title from '@library/ui/title/Title';
import { UIColorsObject } from '@library/ui/UIColors';
import { HCAnalyticsTaskMetrics } from '@services/models/dashboard/hcanalytics-task-metrics';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

type IProps = {
  data: HCAnalyticsTaskMetrics[]
  title: string
  className?: string
}

const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
};

const HCTaskMetricsChart: FC<IProps> = ({ data, title, className }) => {
  const chartData = useMemo(() => {
    return {
      labels: data.map((item) => item.departmentName),
      datasets: [
        {
          data: data.map((item) => item.count),
          backgroundColor: UIColorsObject.green,
          barThickness: 30,
          skipNull: true,
        },
      ],
    };
  }, [data]);

  return (
    <>
      <Title size={'h4'} className={classNames('mb-10')}>
        {title}
      </Title>
      <div className={className}>
        <Bar data={chartData} options={options} />
      </div>
    </>
  );
};

export default observer(HCTaskMetricsChart);
