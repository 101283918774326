
import { Button, ButtonSet, Checkbox, Select, SelectItem, Stack, TextInput } from '@carbon/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { $loader, $modal, $user } from '@library/providers/StoreProvider';
import Title from '@library/ui/title/Title';
import { USER_ROLES_MAPPING, formErrors } from '@library/utils/constants';
import CustomAlertModal from '@library/utils/modals/CustomAlertModal';
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals/index';
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store';
import { showErrorAlert, showSuccessAlert } from '@library/utils/toast';
import { permissionsService } from '@services';
import { UserRole } from '@services/models/userPermissions';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface IProps {
  item: UserRole
  _core: IModalItemCore
}

const schema = z.object({
  id: z.string(),
  login: z.string().min(1, { message: formErrors.required }),
  role: z.string().min(1, { message: formErrors.required }),
  isDelete: z.boolean(),
});

const RolesEditFormModal: FC<IProps> = ({ item, _core }) => {
  /*
   * Обработка формы
   */
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: item.id,
      login: item.userLogin,
      // @ts-ignore
      role: item._UserRole,
      isDelete: false,
    },
    resolver: zodResolver(schema),
  });
  const values = watch();

  const onUpdate = $loader.registerHandler('roles-update-form', async (data) => {
    const response = await permissionsService.updateUserRole({
      id: data.id,
      login: data.login,
      role: data.role,
    });

    if (response.isSuccess) {
      showSuccessAlert('Роль изменена.');
      _core.hide();
    } else {
      showErrorAlert(formErrors.somethingWentWrong);
    }
  });

  const onDelete = $loader.registerHandler('roles-delete-form', async (data) => {
    const response = await permissionsService.deleteUserRole({ id: data.id });

    if (response.isSuccess) {
      showSuccessAlert(`Роль для "${data.login}" удалена.`);
      _core.hide();
    } else {
      showErrorAlert(formErrors.somethingWentWrong);
    }
  });

  const onSubmit = async (data: any) => {
    if (!$user.hasPerm('PERM_USER_ROLE_ADD')) {
      return showErrorAlert(formErrors.forbidden);
    }

    if (values.isDelete) {
      $modal.add(CustomAlertModal, {
        title: 'Удаление роли',
        message: 'Вы точно хотите удалить роль?',
        onDone: () => onDelete(data),
        onDoneButtonType: 'danger',
      });
    } else {
      onUpdate(data);
    }
  };

  return (
    <ModalBase>
      <ModalHeader className="mb-20">
        <Title size="h2">Изменение роли пользователя</Title>
      </ModalHeader>
      <ModalBody className="pb-20">
        <form id="roles-form" onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={6}>
            <TextInput
              id="login"
              labelText="Ник"
              defaultValue={values.login}
              {...register('login')}
              invalid={!!errors.login}
              invalidText={<>{errors.login?.message}</>}
              readOnly={true}
            />
            <Select
              id="role"
              labelText="Роль"
              defaultValue={values.role}
              {...register('role')}
              invalid={!!errors.role}
              invalidText={<>{errors.role?.message}</>}
            >
              {USER_ROLES_MAPPING.map((x) => (
                <SelectItem key={x.id} text={x.label} value={x.id} />
              ))}
            </Select>
            <Checkbox
              id="checkbox"
              labelText="Удалить роль пользователя"
              checked={values.isDelete}
              {...register('isDelete')}
            />
          </Stack>
        </form>
      </ModalBody>
      <ModalFooter>
        <ButtonSet>
          <Button kind="tertiary" onClick={_core.hide}>
            Отмена
          </Button>
          <Button form="roles-form" type="submit">
            Сохранить
          </Button>
        </ButtonSet>
      </ModalFooter>
    </ModalBase>
  );
};

export default observer(RolesEditFormModal);
