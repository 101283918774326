import { $catalog } from '@library/providers/StoreProvider';
import { IFeedbackType } from '@src/modules/catalog/model/catalogStore';
import { toJS } from 'mobx';
import { useMemo, useState } from 'react';



export const useTypeFilter = () => {
  const optionsType = useMemo(() => toJS($catalog.feedbackType), [$catalog.feedbackType]);
  const [types, setTypes] = useState<IFeedbackType[]>([]);

  return {
    types,
    setTypes,
    optionsType,
  };
};
