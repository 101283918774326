
import { ArrowRight } from '@carbon/icons-react';
import { formatDate } from '@helpers/date';
import { isAbsoluteEmpty } from '@helpers/other';
import { HistoryRecord, HistoryRecordChanges } from '@services/models/health-check';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import styles from './HistoryTab.module.scss';

export type HistoryChangeItem = {
  oldValue: string
  newValue: string
}

export type HistoryChange = {
  attribute: string
  operation: string
  label: string
  items: HistoryChangeItem[]
}

export type HistoryChangeLog = {
  id?: string
  changeDate?: Date
  author?: string
  changes?: HistoryChange[][]
}

const operationsMapping: Record<string, string> = {
  add: 'Добавлено',
  edit: 'Изменено',
  delete: 'Удалено',
};

type IProps = {
  data: HistoryRecord[]
  prepareChanges: (changes: HistoryRecordChanges[]) => HistoryChange[]
}

const HistoryTab: FC<IProps> = ({ data = [], prepareChanges }) => {
  const preparedList: HistoryChangeLog[] = useMemo(() => {
    data = _.orderBy(data, 'changeDate', 'desc');

    return data.map((item) => {
      const mapKeys = Object.keys(operationsMapping);
      const changes = mapKeys.map(() => []);

      prepareChanges(item.changes!).forEach((x) => {
        // const index = mapKeys.indexOf(x.operation)
        // if (index !== -1) {
        // @ts-ignore
        // changes[index].push(x)
        // }
        changes[0].push(x);
      });

      return { ...item, changes: changes.filter((x) => x.length) };
    });
  }, [data]);

  return (
    <div className={styles.wrapper}>
      {preparedList.map((el, index) => {
        const itemKey = [el.id, el.author, index].join('_');

        return (
          <div key={itemKey} className={styles.item}>
            <div className={styles.header}>
              <div className={styles.author}>{el.author}</div>
              <div className={styles.date}>{formatDate(el.changeDate, { format: 'dateTime' })}</div>
            </div>

            {el.changes!.map((group, groupIndex) => (
              <div key={[itemKey, groupIndex].join('_')} className={styles.group}>
                {group.map((changes) => (
                  <div key={[itemKey, changes.attribute].join('_')} className={styles.changes}>
                    <div className={styles.changesTitle}>
                      <span className={classNames(styles.indicator, styles[changes.operation])} />
                      <span>{changes.label}</span>
                    </div>
                    {changes.items.map((x, i) => (
                      <div
                        key={[itemKey, changes.attribute, i].join('_')}
                        className={styles.changesItems}
                      >
                        <div className={classNames(styles.changesItem, styles.changesOld)}>
                          {!isAbsoluteEmpty(x.oldValue) && x.oldValue}
                        </div>
                        <div className={classNames(styles.changesItem, styles.changesDelimiter)}>
                          <ArrowRight />
                        </div>
                        <div className={classNames(styles.changesItem, styles.changesNew)}>
                          {!isAbsoluteEmpty(x.newValue) && x.newValue}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default HistoryTab;
