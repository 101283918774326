import InnerErrorPage from '@src/pages/errors/ui/InnerErrorPage';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode } from 'react';



type IProps = {
  condition: Function
  children: ReactNode
}

const PermissionsProvider: FC<IProps> = ({ condition, children }) => {
  const hasAccess = _.isFunction(condition) ? condition() : true;

  return <>{hasAccess ? children : <InnerErrorPage />}</>;
};

export default observer(PermissionsProvider);
