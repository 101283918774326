import { apiRequest } from '@services/api';
import { InlineResponse200 } from '@services/models/userPermissions';

import { AppConfig } from './config';

class PermissionsService {
  baseUrl: string

  constructor(config: AppConfig) {
    this.baseUrl = config.permissionsServiceUrl;
  }

  async login(username: string, password: string) {
    return apiRequest({
      url: this.baseUrl + '/Account/Login',
      method: 'POST',
      data: { Username: username, Password: password },
    });
  }

  async fetch() {
    return apiRequest({
      url: this.baseUrl + '/permissions',
      method: 'GET',
    });
  }

  async fetchUserRoles(params: {
    offset: number
    limit: number
    user?: string
    searchValue?: string
    searchMode?: string
    sortBy?: string
    sortOrder?: string
  }) {
    return apiRequest<InlineResponse200>({
      url: this.baseUrl + '/usersRoles',
      method: 'GET',
      data: params,
    });
  }

  async addUserRole({
    roleSource,
    userLogin,
    userRole,
    subDepartments,
  }: {
    roleSource: string
    userLogin: string
    userRole: string
    subDepartments?: [{ subDepartment: string }]
  }) {
    return apiRequest({
      url: this.baseUrl + '/usersRoles',
      method: 'POST',
      data: {
        roleSource,
        userLogin,
        _UserRole: userRole,
        subDepartments,
      },
    });
  }

  async updateUserRole({ id, login, role }: { id: string; login: string; role: string }) {
    return apiRequest({
      url: this.baseUrl + '/usersRoles/' + id,
      method: 'PUT',
      data: {
        id,
        userLogin: login,
        _UserRole: role,
      },
    });
  }

  async deleteUserRole({ id }: { id: string }): Promise<any> {
    return apiRequest({
      url: this.baseUrl + '/usersRoles/' + id,
      method: 'DELETE',
    });
  }
}

export default PermissionsService;
