
import { Notification, TrashCan } from '@carbon/icons-react';
import { notificationsService } from '@services';
import {
  HCMNotification,
  HCMNotificationList,
  HCMNotificationStatusEnum,
} from '@services/models/notifications';
import { useOnClickOutside } from '@src/library/utils/hooks';
import classNames from 'classnames';
import React, { FC, MouseEvent, memo, useCallback, useEffect, useState } from 'react';

import styles from './NotificationsBell.module.scss';

const REFETCH_INTERVAL = 30000;
const LIMIT_COUNT = 5;

type IProps = {
  className: string
}

const NotificationBell: FC<IProps> = ({ className }) => {
  const [unreadCount, setUnreadCount] = useState<number | null>(null);
  const [fullCount, setFullCount] = useState<number>(0);
  const [notifList, setNotifList] = useState<HCMNotification[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isShowMore = notifList?.length < fullCount;
  const isHasNotif = notifList?.length > 0;

  useEffect(() => {
    getCount();

    const interval = setInterval(() => {
      getCount();
    }, REFETCH_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  const getCount = async () => {
    const response = await notificationsService.getNotifCount();

    if (response.isSuccess) {
      setUnreadCount(response.data?.count);
    }
  };

  const getNotifications = useCallback(
    async (offset: number) => {
      const response = await notificationsService.getNotifList({ limit: LIMIT_COUNT, offset });

      if (response.isSuccess && (response.data?.data as HCMNotificationList)) {
        setNotifList((prev) => [...prev, ...response.data?.data]);
        setFullCount(response.data?.paging?.count);

        const ids = (response.data?.data as HCMNotification[])
          ?.filter((item) => Number(item?.status) === HCMNotificationStatusEnum.NUMBER_0)
          ?.map((item) => item.id);

        if (ids?.length > 0) {
          readNotifications(ids as string[]);
        }
      }
    },
    [offset],
  );

  const readNotifications = async (ids: string[]) => {
    const response = await notificationsService.markreadNotif(ids);

    if (response.isSuccess) {
      getCount();
    }
  };

  const handleClose = () => {
    if (!isOpen) {return;}

    setIsOpen(false);
    setNotifList([]);
    getCount();
  };

  const modalRef = useOnClickOutside(handleClose);

  const handleOpenNotif = () => {
    setIsOpen(true);
    setOffset(0);
    getNotifications(0);
  };

  const toggleBell = (e: MouseEvent) => {
    e.stopPropagation();

    if (isOpen) {
      handleClose();
    } else {
      handleOpenNotif();
    }
  };

  const handleRemoveNotif = async (id: string) => {
    const response = await notificationsService.deleteNotif(id);

    if (response.isSuccess) {
      setNotifList((prev) => prev?.filter((item) => item?.id !== id));
      setOffset((prev) => {
        const newOffset = prev + LIMIT_COUNT + 1;
        getNotifications(newOffset);

        return newOffset;
      });
    }
  };

  const handleRemoveAll = async () => {
    const response = await notificationsService.deleteAllNotif();

    if (response.isSuccess) {
      setNotifList([]);
    }
  };

  const showMore = () => {
    setOffset((prev) => {
      const newOffset = prev + LIMIT_COUNT;
      getNotifications(newOffset);

      return newOffset;
    });
  };

  return (
    <div ref={modalRef} className={styles.wrapper}>
      <div
        className={classNames(className, styles.button, isOpen && styles.buttonActive)}
        onClick={(e) => toggleBell(e)}
      >
        <div className={styles.buttonInner}>
          {unreadCount && <div className={styles.count}>{unreadCount}</div>}
          <Notification size={20} />
        </div>
      </div>
      {isOpen && (
        <div className={styles.modalContainer}>
          <div className={styles.notifContainer}>
            {isHasNotif ? (
              notifList?.map(
                (item) =>
                  item?.text && (
                    <div
                      key={'notif ' + item?.id}
                      className={classNames(
                        styles.item,
                        Number(item?.status) === 0 && styles.item_grey,
                      )}
                    >
                      <p dangerouslySetInnerHTML={{ __html: item?.text }} />
                      <TrashCan onClick={() => handleRemoveNotif(item.id as string)} />
                    </div>
                  ),
              )
            ) : (
              <p className="content-box text-center">У вас нет уведомлений</p>
            )}
          </div>

          {isHasNotif && (
            <div className={styles.toolbar}>
              {isShowMore && (
                <a onClick={showMore} className="cursor-pointer">
                  Посмотреть далее
                </a>
              )}
              <a onClick={handleRemoveAll} className="cursor-pointer">
                Очистить все уведомления
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(NotificationBell);
