import axios from 'axios';

export interface AppConfig {
  env: string
  useOidc: boolean
  oidcConfig: any //TODO specify type
  authServiceUrl: string
  permissionsServiceUrl: string
  personServiceUrl: string
  catalogServiceUrl: string
  dashboardServiceUrl: string
  devPlanServiceUrl: string
  taskServiceUrl: string
  notificationsServiceUrl: string
  loaded: boolean
  aidayUrl: string
}
let instance: AppConfig = {
  env: '',
  useOidc: false,
  oidcConfig: undefined,
  authServiceUrl: '',
  permissionsServiceUrl: '',
  personServiceUrl: '',
  catalogServiceUrl: '',
  dashboardServiceUrl: '',
  devPlanServiceUrl: '',
  taskServiceUrl: '',
  notificationsServiceUrl: '',
  loaded: false,
  aidayUrl: '',
};

const replaceEnvVariables = (obj: AppConfig) => {
  Object.keys(obj).forEach((key) => {
    const typedKey = key as keyof AppConfig;

    if (typeof obj[typedKey] === 'string' && obj[typedKey].startsWith('$REACT_APP_')) {
      const envKey = obj[typedKey].substring(1);
      obj[typedKey] = (process.env[envKey] || '') as never;
    } else if (typeof obj[typedKey] === 'object') {
      replaceEnvVariables(obj[typedKey]);
    }
  });
};

export const loadConfig = async () => {
  if (instance && instance.loaded) {
    return instance;
  }

  const response = await axios.get('/config.json');
  instance = response.data;

  replaceEnvVariables(instance);

  instance.loaded = true;

  return instance;
};

export const getConfig = () => {
  return instance;
};
