
import {
  Button,
  ButtonSet,
  Column,
  ComboBox,
  DatePickerInput,
  RadioButton,
  RadioButtonGroup,
  Row,
  Stack,
} from '@carbon/react';
import { useRouterSearchParams } from '@helpers/router'
import { isEqualLogin } from '@helpers/text.js'
import { zodResolver } from '@hookform/resolvers/zod'
import AuthorAndCreateDate from '@library/ui/author-and-createdate/AuthorAndCreateDate';
import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker';
import FormEditLink from '@library/ui/formEditLink/FormEditLink';
import AutoSizedTextArea from '@library/ui/textarea/AutoSizedTextArea';
import Title from '@library/ui/title/Title';
import {
  COMPETENCE_CAUSE,
  FREE_TEXT_CAUSE,
  HEALTHCHECK_CAUSE,
  ONBOARDING_CAUSE,
  PERFORMANCE_CAUSE,
  formErrors,
} from '@library/utils/constants';
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals';
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store';
import { showErrorAlert } from '@library/utils/toast';
import { catalogService, humanTaskService, personService } from '@services'
import { PersonCardForList } from '@services/models/person'
import { Task } from '@services/models/task'
import { $loader, $user } from '@src/library/providers/StoreProvider';
import MentionsTextarea from '@src/library/ui/mentions-textarea/MentionsTextarea';
import { useLoader } from '@src/library/utils/hooks'
import { EditTaskStatus } from '@src/modules/editTaskStatus'
import SingleDepartmentSelect from '@src/modules/filters/ui/form-tems/SingleDepartmentSelect'
import filterStyles from '@src/modules/filters/ui/styles/Filters.module.scss'
import dayjs from 'dayjs'
import { Hook } from 'flatpickr/dist/types/options'
import { observer } from 'mobx-react-lite'
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod'

import { useTaskWatchers } from '../model/hooks/UseTaskWatchers'

import ChangeTaskStatus from './changeTaskStatus/ChangeTaskStatus'
import HealthCheckDropdown from './form/HealthcheckDropdown'
import OnboardingDropdown from './form/OnboardingDropdown'
import PerformanceReviewDropdown from './form/PerformanceReviewDropdown'
import SkillReviewDropdown from './form/SkillReviewDropdown'
import TaskCauseDropdown from './form/TaskCauseDropDown'
import styles from './TaskFormModal.module.scss'
import TaskTabs from './taskTabs/TaskTabs'

type IProps = {
  taskId?: string
  edit?: boolean
  _core: IModalItemCore
}

const schema = z
  .object({
    dueTo: z
      .date()
      .optional() //изначально пустое, но при сохранении должно быть заполнено
      .refine((data) => data, { message: formErrors.required }),
    theme: z.string().min(1, { message: formErrors.required }),
    description: z.string().min(1, { message: formErrors.required }),
    taskCause: z.object({
      causeText: z.string().optional(),
      causeType: z.string().min(1, { message: formErrors.required }),
      causeObjectId: z.string().optional(),
      causeObjectLink: z.union([z.undefined(), z.string().url()]),
    }),
    performer: z.object({
      nickName: z.string().min(1, { message: formErrors.required }),
      department: z.string().optional(),
    }),
    personObject: z.object({
      nickName: z.string().optional(),
      department: z.string().optional(),
    }),
    departmentObject: z.object({
      code: z.string().optional(),
      name: z.string().optional(),
    }),
  })
  // todo: move to field refine
  .superRefine((schema, ctx) => {
    if (schema.taskCause.causeType === FREE_TEXT_CAUSE && schema.taskCause.causeText === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['taskCause.causeText'],
        message: formErrors.required,
      });
    }

    if (schema.taskCause.causeType === HEALTHCHECK_CAUSE && !schema.taskCause.causeObjectId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['taskCause.causeObjectId'],
        message: formErrors.required,
      });
    }

    if (
      (schema.taskCause.causeType === PERFORMANCE_CAUSE ||
        schema.taskCause.causeType === COMPETENCE_CAUSE) &&
      !schema.taskCause.causeObjectLink
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['taskCause'],
        message: formErrors.required,
      });
    }

    if (schema.departmentObject.code === '' && schema.personObject.nickName === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['personObject.nickName'],
        message: formErrors.required,
      });

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['departmentObject.code'],
        message: formErrors.required,
      });
      //почему-то массивом отдать не получилось
    }
  });
export type ITaskFormProperties = z.infer<typeof schema>

export const OBJECT_IS_DEPARTMENT = 'object-is-department';
export const OBJECT_IS_PERSON = 'object-is-person';

const TaskForm: FC<IProps> = ({ taskId, edit, _core }) => {
  const searchParams = useRouterSearchParams();
  const onShowModal = () => taskId && searchParams.set('id', taskId);
  const onHideModal = () => taskId && searchParams.remove('id');

  const [task, setTask] = useState<Task>();
  const [objectType, setObjectType] = useState(OBJECT_IS_DEPARTMENT);
  const [personsList, setPersonsList] = useState<PersonCardForList[]>([]);
  const [mappedPersons, setMappedPersons] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [mentions, setMentions] = useState<string[]>([]);

  const isNew = !taskId;
  const isView = !!taskId;
  const isCanEdit = task ? $user.canEditTask(task.author!) : false;
  const canScheduleTask = task ? $user.canScheduleTask(task.performer?.nickName) : false;
  const [isEdit, setIsEdit] = useState(isView && edit);

  /*
   * Form controller
   */
  const defaultValues: ITaskFormProperties = {
    dueTo: undefined,
    theme: '',
    description: '',
    taskCause: {
      causeText: '',
      causeType: $user.hasPerm('PERM_TASK_HC') ? HEALTHCHECK_CAUSE : FREE_TEXT_CAUSE,
      causeObjectId: undefined,
      causeObjectLink: undefined,
    },
    performer: {
      nickName: '',
      department: '',
    },
    personObject: {
      nickName: '',
      department: '',
    },
    departmentObject: {
      code: '',
      name: '',
    },
  };

  function getDefaultValues() {
    return async () => {
      if (isNew) {
        return defaultValues;
      } else {
        const response = await humanTaskService.get(taskId!);

        if (response.isSuccess && response.data) {
          setTask(response.data);

          let data = response.data as unknown as ITaskFormProperties;

          if (data.dueTo) {
            data.dueTo = dayjs(data.dueTo).toDate();
          }

          if (data.personObject?.nickName) {
            setObjectType(OBJECT_IS_PERSON);
          }

          if (data.departmentObject?.code) {
            setObjectType(OBJECT_IS_DEPARTMENT);
          }

          //override nulls with default values
          const mapper = (obj: any, defaults: any) => {
            return _.mapValues(defaults, (x: any, key: string) => {
              if (_.isObject(x)) {
                obj[key] = mapper(obj[key] ?? {}, x);
              }

              if (_.isNil(obj[key])) {return x;}

              return obj[key];
            });
          };

          return mapper(data, defaultValues) as ITaskFormProperties;
        } else {
          return defaultValues;
        }
      }
    };
  }

  const refetchTask = async () => {
    const response = await humanTaskService.get(taskId!);

    if (response.isSuccess && response.data) {
      setTask(response.data);
    }
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    formState: { errors, isLoading: isFormLoading, isDirty },
  } = useForm<ITaskFormProperties>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(schema),
  });

  /*
   * Data loading
   */
  const { isWatchersLoading, watchers, isWatching, addWatcher, removeWatcher } = useTaskWatchers(
    taskId!,
  );

  const isDepsPersLoading = useLoader(async () => {
    const manager = $user.hasPerm('PERM_TASK_MANAGER')
      ? $user.loggedInUser.preferredUsername
      : undefined;

    const [resDeps, resPers] = await Promise.all([
      catalogService.fetchDepartments(manager, manager),
      personService.fetch(manager, undefined, manager),
    ]);

    if (resDeps.isSuccess && resDeps.data) {
      const data = _.orderBy(resDeps.data[0].data, 'name', 'asc');
      setDepartmentList(data);
    }

    if (resPers.isSuccess && resPers.data?.data) {
      const data = _.orderBy(resPers.data?.data, 'nickName', 'asc');
      const mapped = data.map((user: any) => ({
        key: [user.id, user.nickName].join('_'),
        text: user.nickName!,
        value: user.nickName!,
      }));
      setMappedPersons(mapped);
      setPersonsList(data);
    }
  });

  const isLoading = isDepsPersLoading || isWatchersLoading || isFormLoading;

  /*
   * Prepared values
   */
  const values = watch();

  useEffect(() => {
    if (!isLoading) {
      // console.log('values', values)
      // console.log('errors', errors)
    }
  }, [isLoading, values]);

  /*
   * Field handlers
   */
  const onDueToChange: Hook = (dates) => {
    // @ts-ignore
    setValue('dueTo', dates[0]);
  };

  async function handleWatchers() {
    if (isWatching) {
      const watcher = watchers.find((x) =>
        isEqualLogin(x.person!, $user.loggedInUser.preferredUsername),
      );

      if (!watcher) {
        return;
      }

      removeWatcher(watcher.id!);
    } else {
      addWatcher($user.loggedInUser.preferredUsername);
    }
  }

  useEffect(() => {
    if (isDirty) {
      clearTaskCause();
    }

    if (values.personObject?.nickName) {
      const person = personsList.find((x) => x.nickName === values.personObject.nickName);

      if (person) {
        setValue('personObject.department', person!.department!.code);
      }
    }
  }, [values.departmentObject?.code, values.personObject?.nickName]);

  function clearTaskCause() {
    setValue('taskCause.causeType', defaultValues.taskCause.causeType);
    setValue('taskCause.causeText', defaultValues.taskCause.causeText);
    setValue('taskCause.causeObjectId', defaultValues.taskCause.causeObjectId);
    setValue('taskCause.causeObjectLink', defaultValues.taskCause.causeObjectLink);
  }

  function handleObjectTypeChange(selection: any) {
    setObjectType(selection);

    /*
    здесь очищается объект, изменение которого в свою очередь очищает причину задачи
    */
    if (selection === OBJECT_IS_PERSON) {
      setValue('departmentObject', defaultValues.departmentObject, { shouldDirty: true });
    } else if (selection === OBJECT_IS_DEPARTMENT) {
      setValue('personObject', defaultValues.personObject, { shouldDirty: true });
    }
  }

  const { isDirty: isTaskCauseDirty } = getFieldState('taskCause');
  const canEdit = useMemo(() => {
    return {
      personObject: isNew || (isEdit && isCanEdit),
      departmentObject: isNew || (isEdit && isCanEdit),
      'taskCause.causeType': isNew || (isEdit && isCanEdit),
      'taskCause.causeText': isNew || (isEdit && isCanEdit),
      'taskCause.causeObjectId': isNew || (isEdit && isCanEdit),
      'taskCause.causeObjectLink': isNew || (isEdit && isCanEdit),
      dueTo: isNew || (isEdit && (isCanEdit || canScheduleTask)),
      theme: isNew || (isEdit && isCanEdit),
      performer: isNew || (isEdit && isCanEdit),
      description: isNew || (isEdit && isCanEdit),
    };
  }, [isNew, isEdit, isCanEdit]);

  const submitIsAvailable = _.some(canEdit, (x: any) => x);

  const onSubmit = $loader.registerHandler('task-create-or-update-form', async (data) => {
    let payload = data;

    if (payload.dueTo) {
      payload.dueTo = dayjs(payload.dueTo).format('YYYY-MM-DD');
    }

    if (payload.taskCause.causeType === FREE_TEXT_CAUSE) {
      //сбрасываем все кроме типа и текста
      payload.taskCause = {
        ...defaultValues.taskCause,
        causeType: payload.taskCause.causeType,
        causeText: payload.taskCause.causeText,
      };
    }

    if (payload.description) {
      payload = {
        ...payload,
        mentionedList: [
          {
            taskAttribute: 'description',
            mentionedPerson: mentions,
          },
        ],
      };
    }

    let response;

    if (isView) {
      payload.id = taskId;
      response = await humanTaskService.updateTask(payload);
    } else {
      payload.author = $user.loggedInUser.preferredUsername;
      response = await humanTaskService.createTask(payload);
    }

    if (response.isSuccess) {
      _core.hide();
    } else {
      showErrorAlert(formErrors.somethingWentWrong);
    }
  });

  return (
    <ModalBase size={isView ? 'xxl' : 'md'} onShow={onShowModal} onHide={onHideModal}>
      <ModalHeader className="mb-20">
        <Title size="h2">
          {isEdit ? (
            <>Изменение задачи {task?.code}</>
          ) : isView ? (
            <>
              Просмотр задачи {task?.code}{' '}
              {!isEdit && (isCanEdit || canScheduleTask) && (
                <small className="a" onClick={() => setIsEdit(true)}>
                  Редактировать
                </small>
              )}
            </>
          ) : (
            <>Создание задачи</>
          )}
        </Title>
      </ModalHeader>
      <ModalBody isLoading={isLoading} className="pb-20">
        <Row>
          <Column lg={isView ? 8 : 16}>
            {isView && (
              <div className={styles.taskInfo}>
                <Stack gap={6}>
                  <Row>
                    <ButtonSet className={styles.buttonsWrap}>
                      <ChangeTaskStatus task={task} refetchTask={refetchTask} />
                      <Button
                        kind="tertiary"
                        onClick={handleWatchers}
                        className={styles.changeTaskStatusButton}
                        size="sm"
                      >
                        {isWatching ? 'Остановить отслеживание' : 'Отслеживать'}
                      </Button>
                    </ButtonSet>
                  </Row>
                  <Row narrow={true}>
                    <Column lg={7}>
                      <div>
                        Статус:{' '}
                        {task && <EditTaskStatus task={task!} disabled={true} silent={false} />}
                      </div>
                    </Column>
                    <Column lg={9}>
                      <AuthorAndCreateDate author={task?.author} createDate={task?.createDate} />
                    </Column>
                  </Row>
                </Stack>
              </div>
            )}
            <form id="task-form" noValidate={true} onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={6}>
                <RadioButtonGroup
                  legendText="C кем поработать"
                  readOnly={!canEdit['personObject'] && !canEdit['departmentObject']}
                  name="objectSelector"
                  onChange={handleObjectTypeChange}
                >
                  <RadioButton
                    labelText="Подразделение"
                    value={OBJECT_IS_DEPARTMENT}
                    checked={objectType === OBJECT_IS_DEPARTMENT}
                    id="objectDepartment"
                  />
                  <RadioButton
                    labelText="Сотрудник"
                    value={OBJECT_IS_PERSON}
                    id="objectPerson"
                    checked={objectType === OBJECT_IS_PERSON}
                  />
                </RadioButtonGroup>
                {objectType === OBJECT_IS_PERSON && (
                  <ComboBox
                    /* @ts-ignore */
                    id="personObject"
                    placeholder="Не выбрано"
                    items={mappedPersons?.filter(
                      (x: any) => !isEqualLogin(x.text, $user.loggedInUser.preferredUsername),
                    )}
                    itemToString={(item: any) => (item ? item.text : '')}
                    onChange={(item: any) => {
                      setValue('personObject.nickName', item?.selectedItem?.text, {
                        shouldDirty: true,
                      });
                    }}
                    selectedItem={
                      values.personObject?.nickName && {
                        text: values.personObject.nickName,
                        value: values.personObject.nickName,
                      }
                    }
                    readOnly={!canEdit['personObject']}
                    downshiftProps={!canEdit['personObject'] ? { isOpen: false } : undefined}
                    invalid={!!errors.personObject}
                    invalidText={<>{errors.personObject?.nickName?.message}</>}
                    className={filterStyles.comboBox}
                  />
                )}
                {objectType === OBJECT_IS_DEPARTMENT && (
                  <SingleDepartmentSelect
                    id="departmentObject"
                    departmentCode={values?.departmentObject?.code}
                    departmentList={departmentList}
                    onChange={(value) => {
                      setValue('departmentObject.code', value, { shouldDirty: true });
                    }}
                    readOnly={!canEdit['departmentObject']}
                    invalid={!!errors.departmentObject}
                    invalidText={errors.departmentObject?.code?.message}
                  />
                )}
                <Fragment key={values.taskCause?.causeType}>
                  <TaskCauseDropdown
                    labelText="Причина появления задачи"
                    //@ts-ignore
                    control={control}
                    name="taskCause"
                    objectType={objectType}
                    readOnly={!canEdit['taskCause.causeType']}
                  />
                  {values.taskCause?.causeType === FREE_TEXT_CAUSE && (
                    <AutoSizedTextArea
                      id="taskCause.causeText"
                      labelText="Описание причины задачи"
                      {...register('taskCause.causeText')}
                      enableCounter={true}
                      maxCount={1000}
                      invalid={!!errors.taskCause?.causeText}
                      invalidText={<>{errors.taskCause?.causeText?.message}</>}
                      readOnly={!canEdit['taskCause.causeText']}
                    />
                  )}
                  {values.taskCause?.causeType === HEALTHCHECK_CAUSE && (
                    <HealthCheckDropdown
                      labelText="HealthCheck"
                      //@ts-ignore (нужен интерфейс полей формы чтобы это работало)
                      control={control}
                      name="taskCause"
                      readOnly={!canEdit['taskCause.causeObjectId']}
                    />
                  )}
                  {values.taskCause?.causeType === ONBOARDING_CAUSE && (
                    <OnboardingDropdown
                      labelText="Адаптация"
                      //@ts-ignore (нужен интерфейс полей формы чтобы это работало)
                      control={control}
                      name="taskCause"
                      readOnly={!canEdit['taskCause.causeObjectId']}
                    />
                  )}

                  {(values.taskCause?.causeType === PERFORMANCE_CAUSE ||
                    values.taskCause?.causeType === COMPETENCE_CAUSE) && (
                    <FormEditLink
                      value={values.taskCause?.causeObjectLink!}
                      text={values.taskCause?.causeText!}
                      initOpen={!isView || isTaskCauseDirty}
                      readOnly={!canEdit['taskCause.causeObjectLink']}
                    >
                      {values.taskCause?.causeType === PERFORMANCE_CAUSE && (
                        <PerformanceReviewDropdown
                          name="taskCause"
                          //@ts-ignore (нужен интерфейс полей формы чтобы это работало)
                          control={control}
                          labelText="Оценка результативности"
                          readOnly={!canEdit['taskCause.causeObjectLink']}
                        />
                      )}
                      {values.taskCause?.causeType === COMPETENCE_CAUSE && (
                        <SkillReviewDropdown
                          name="taskCause"
                          //@ts-ignore (нужен интерфейс полей формы чтобы это работало)
                          control={control}
                          labelText="Оценка компетенций"
                          readOnly={!canEdit['taskCause.causeObjectLink']}
                        />
                      )}
                    </FormEditLink>
                  )}
                </Fragment>
                <ComboBox
                  /* @ts-ignore */
                  id="performer"
                  titleText="Исполнитель"
                  items={mappedPersons?.filter(
                    (x: any) => !isEqualLogin(x.text, $user.loggedInUser.preferredUsername),
                  )}
                  itemToString={(item: any) => (item ? item.text : '')}
                  onChange={(item: any) =>
                    setValue('performer.nickName', item?.selectedItem?.text, { shouldDirty: true })
                  }
                  selectedItem={
                    values.performer?.nickName && {
                      text: values.performer.nickName,
                      value: values.performer.nickName,
                    }
                  }
                  readOnly={!canEdit['performer']}
                  downshiftProps={!canEdit['performer'] ? { isOpen: false } : undefined}
                  invalid={!!errors.performer}
                  invalidText={<>{errors.performer?.nickName?.message}</>}
                  className={filterStyles.comboBox}
                />
                <HcmDatePicker
                  value={values.dueTo ?? ''}
                  onChange={onDueToChange}
                  onClose={onDueToChange}
                  invalid={!!errors.dueTo}
                  invalidText={<>{errors.dueTo?.message}</>}
                  readOnly={!canEdit['dueTo']}
                  className={styles.calendar}
                >
                  <DatePickerInput
                    labelText="Выполнить до"
                    placeholder="dd.mm.yyyy"
                    id="dueTo"
                    invalid={!!errors.dueTo}
                    invalidText={<>{errors.dueTo?.message}</>}
                    readOnly={!canEdit['dueTo']}
                  />
                </HcmDatePicker>
                <AutoSizedTextArea
                  id="theme"
                  labelText="Суть задачи"
                  enableCounter={true}
                  maxCount={250}
                  invalid={!!errors.theme}
                  invalidText={<>{errors.theme?.message}</>}
                  {...register('theme')}
                  readOnly={!canEdit['theme']}
                />
                <MentionsTextarea
                  labelText="Детали задачи"
                  value={values.description}
                  onChange={(val) => setValue('description', val)}
                  enableCounter={true}
                  maxCount={1000}
                  id="description"
                  invalid={!!errors.description}
                  invalidText={errors.description?.message}
                  persons={personsList}
                  mentions={mentions}
                  setMentions={setMentions}
                  disabled={!canEdit['description']}
                />
              </Stack>
            </form>
          </Column>
          {isView && task && (
            <Column lg={8}>
              <TaskTabs task={task} watchers={watchers} persons={personsList} />
            </Column>
          )}
        </Row>
      </ModalBody>
      {!isLoading && submitIsAvailable && (
        <ModalFooter>
          <ButtonSet>
            <Button kind="tertiary" onClick={_core.hide}>
              Отмена
            </Button>
            <Button form="task-form" type="submit">
              Сохранить
            </Button>
          </ButtonSet>
        </ModalFooter>
      )}
    </ModalBase>
  );
};

export default observer(TaskForm);
