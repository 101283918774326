
import Title from '@library/ui/title/Title';
import { UIColors, getRandomColor } from '@library/ui/UIColors';
import { OnboardingAnalytics } from '@services/models/dashboard';
import type { ChartData, ChartOptions } from 'chart.js';
import { ChartDataset } from 'chart.js/dist/types';
import classNames from 'classnames';
import React, { type FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import { emptyPlaceHolderPlugin, wrapLabel } from '../../model/helpers/formatters';

interface IProps {
  analytics: OnboardingAnalytics[]
  title: string
  className?: string
}

const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        callback (value, index) {
          return wrapLabel(this.getLabelForValue(index));
        },
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
};

const OnboardingsChartYearMetrics: FC<IProps> = ({ analytics = [], title, className }) => {
  const data = useMemo(() => {
    const labels = analytics.map((x) => x.departmentName!);

    const availableYears = _.sortBy([
      ...new Set(analytics.flatMap((item) => item.yearMetrics?.map((x) => x.year))),
    ]);

    const datasets = availableYears
      .reverse()
      .map((year, index) => {
        const datasetData = analytics.map((item) => {
          const foundMetric = item.yearMetrics?.find((x) => x.year === year);

          return foundMetric ? foundMetric.averageDuration : 0;
        });

        return {
          label: year,
          data: datasetData,
          backgroundColor: UIColors[index] || getRandomColor(),
          maxBarThickness: 40,
        } as ChartDataset<'bar'>;
      })
      .reverse();

    return {
      labels,
      datasets,
    } as ChartData<'bar'>;
  }, [analytics]);

  return (
    <>
      <Title size={'h4'} className={classNames('mb-10')}>
        {title}
      </Title>
      <div className={className}>
        <Bar options={options} data={data} plugins={[...emptyPlaceHolderPlugin]} />
      </div>
    </>
  );
};

export default OnboardingsChartYearMetrics;
