
import { Select, SelectItem } from '@carbon/react';
import { currentQuarter, formatQuarter } from '@helpers/date';
import dayjs from 'dayjs';
import React, { ChangeEvent, FC, ReactNode } from 'react';

type QuarterSelectProps = {
  labelText: ReactNode
  id: string
  defaultValue?: any
  value?: any
  invalid?: boolean
  readOnly?: boolean
  invalidText?: ReactNode
  onChange?: Function
  //  ref?: any
  name?: string
}

const QuarterSelect: FC<QuarterSelectProps> = (props) => {
  const defaultValue = formatDateInternal(props.defaultValue ?? currentQuarter());

  const options = [];
  let qDate = dayjs(currentQuarter());

  for (let i = -3; i < 4; i++) {
    const d = qDate.add(i, 'quarter');
    options.push(d.format('YYYY-MM-DD'));
  }

  if (options.indexOf(defaultValue) === -1) {
    options.push(defaultValue);
  }

  function formatDateInternal(d: Date) {
    const qStart = dayjs(d).startOf('quarter');

    return dayjs(qStart).format('YYYY-MM-DD');
  }

  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    const d = dayjs(e.target.value).toDate();
    props.onChange && props.onChange(d);
  }

  //По умолчанию текущий квартал
  const value = props.value ? formatDateInternal(props.value) : undefined;

  return (
    <Select {...props} value={value} defaultValue={defaultValue} onChange={onChange}>
      {options.map((x) => (
        <SelectItem key={x} text={formatQuarter(x)} value={x} />
      ))}
    </Select>
  );
};

export default QuarterSelect;
