
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react';
import InlineTabFormWrapper from '@library/layouts/tabs/InlineTabFormWrapper';
import { $loader, $user } from '@library/providers/StoreProvider';
import { showErrorAlert } from '@library/utils/toast';
import { humanTaskService } from '@services';
import { HistoryRecord } from '@services/models/health-check';
import { PersonCardForList } from '@services/models/person/person-card-for-list';
import { Task, TaskWatcher } from '@services/models/task';
import { formErrors } from '@src/library/utils/constants';
import { AddCommentForm, CommentsTab } from '@src/modules/comments';
import HistoryTab, { HistoryChange } from '@src/modules/history/ui/HistoryTab';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';

import styles from './TaskTabs.module.scss';
import WatchersTab from './WatchersTab';

type IProps = {
  task: Task
  watchers?: TaskWatcher[]
  persons?: PersonCardForList[]
}

const TASK_ADD_COMMENT_FORM = 'task-add-comment-form';

const TaskTabs: FC<IProps> = ({ task, watchers, persons }) => {
  const [comments, setComments] = useState(task.commentsList || []);
  const [history, setHistory] = useState(task.historyList || []);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const onCommentAdd = $loader.registerHandler(TASK_ADD_COMMENT_FORM, async (data) => {
    const resAdd = await humanTaskService.addComment({
      id: task.id!,
      commentText: data.comment,
      mentionedList: data.mentionedList,
    });

    if (resAdd.isSuccess) {
      const resUpdate = await humanTaskService.getComments({ id: task.id! });

      if (resUpdate.isSuccess) {
        setComments(resUpdate.data);
      }
    } else {
      showErrorAlert(formErrors.somethingWentWrong);
    }
  });

  return (
    <Tabs defaultSelectedIndex={selectedIndex} onChange={(x) => setSelectedIndex(x.selectedIndex)}>
      <TabList
        aria-label="list-of-comments-and-history"
        contained={true}
        fullWidth={true}
        className={styles.tabs}
      >
        <Tab>Комментарии ({comments.length})</Tab>
        <Tab>История изменений ({history.length})</Tab>
        <Tab>Отслеживают ({watchers?.length})</Tab>
      </TabList>
      <InlineTabFormWrapper className={'mt-10'}>
        {selectedIndex === 0 && (
          <AddCommentForm
            formId={TASK_ADD_COMMENT_FORM}
            onCommentAdd={onCommentAdd}
            persons={persons}
          />
        )}
      </InlineTabFormWrapper>
      <TabPanels>
        {selectedIndex === 0 && (
          <TabPanel className="p-0">
            <CommentsTab comments={comments} />
          </TabPanel>
        )}
        {selectedIndex === 1 && (
          <TabPanel className="p-0">
            <HistoryTab
              // фикс типизации пока не обновлен контракт и модели задач
              data={history as HistoryRecord[]}
              prepareChanges={(changes) => {
                const fieldsMapping: Record<string, string> = {
                  object: 'С кем поработать',
                  causeType: 'Причина появления задачи',
                  causeText: 'Причина появления задачи',
                  performer: 'Исполнитель',
                  dueTo: 'Выполнить до',
                  theme: 'Суть задачи',
                  description: 'Детали задачи',
                };
                changes = _.sortBy(changes, (x) => Object.keys(fieldsMapping).indexOf(x.attribute!));

                let result: HistoryChange[] = [];
                changes.forEach((x) => {
                  const tmp: HistoryChange = {
                    attribute: x.attribute!,
                    operation: x.operation!,
                    label: fieldsMapping[x.attribute!] || x.attribute!,
                    items: [{ oldValue: x.oldValue!, newValue: x.newValue! }],
                  };

                  if (x.attribute === 'causeText') {
                    const typeIndex = result.findIndex((x) => x.attribute === 'causeType');

                    if (typeIndex !== -1) {
                      result[typeIndex].items = [...result[typeIndex].items, ...tmp.items];

                      return;
                    }
                  }

                  result.push(tmp);
                });

                return result;
              }}
            />
          </TabPanel>
        )}
        {selectedIndex === 2 && (
          <TabPanel className="p-0">
            <WatchersTab
              watchers={watchers}
              persons={persons}
              readOnly={!$user.hasPerm('PERM_TASK_WATCHER_EDIT')}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default observer(TaskTabs);
