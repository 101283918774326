import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { dateFormats } from '@library/utils/constants';

import { wordForNumeric } from './text.js';

dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);

dayjs.locale('ru');

export interface IQuarter {
  id: number
  label: string
  value: {
    startDate: string
    endDate: string
  }
}

export function formatDate(
  value: any,
  { format = dateFormats.date }: { format?: keyof typeof dateFormats | string } = {},
) {
  if (format in dateFormats) {
    format = dateFormats[format as keyof typeof dateFormats];
  }

  return value && dayjs(value).format(format);
}

export function formatPeriod(start: any, end: any, params = {}) {
  return [formatDate(start, params), formatDate(end, params)].join(' - ');
}

export function latinQNum(n: number) {
  switch (n) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    default:
      return '??';
  }
}

export function formatQuarter(date: any, parseFormat?: string) {
  let format = undefined;

  if (typeof date === 'string') {
    if (date.indexOf('.') !== -1) {
      format = 'DD.MM.YYYY';
    } else if (parseFormat) {
      format = parseFormat;
    }
  }

  const dateObj = dayjs(date, format);
  const q = dateObj.quarter();
  const year = dateObj.year();

  return `${latinQNum(q)} квартал ${year}`;
}

export function currentQuarter() {
  return dayjs().startOf('quarter').toDate();
}

export function getDurationInDays(start: any, end: any) {
  const endDate = dayjs(end);
  const days = endDate.diff(start, 'day') + 1;

  return `${days} ${wordForNumeric(days, ['день', 'дня', 'дней'])}`;
}

export function mustParseDate(param: string) {
  if (!param) {
    return undefined;
  }

  const djsDate = dayjs(param, Object.values(dateFormats), true);

  if (!djsDate.isValid()) {
    return undefined;
  }

  return djsDate.toDate();
}

export const getQuarters = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  const start = dayjs(startDate).startOf('quarter');
  const end = dayjs(endDate).endOf('quarter');

  const quarters: IQuarter[] = [];
  let id = 0;

  for (let date = start; date.isBefore(end) || date.isSame(end); date = date.add(1, 'quarter')) {
    const quarterLabel = formatQuarter(date);
    quarters.push({
      label: quarterLabel,
      value: {
        startDate: date.startOf('quarter').format('YYYY-MM-DD'),
        endDate: date.endOf('quarter').format('YYYY-MM-DD'),
      },
      id: id++,
    });
  }

  return quarters;
};
