

import { localStore } from '@helpers/other';
import AuthError from '@library/providers/ui/AuthError';
import { tokenService } from '@services';
import { getConfig } from '@services/config';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

type IProps = {
  children: ReactNode
}

const OidcSignOnProvider: FC<IProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  async function init(user: any) {
    await tokenService.setUser(user);
    setIsLoading(false);
  }

  const auth = useAuth();

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect({
        redirect_uri: window.location.href,
      });
    }

    if (auth.isAuthenticated && !auth.isLoading) {
      init(auth.user);
    }
  }, [auth]);

  /*
  TODO
  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>
    case 'signoutRedirect':
      return <div>Signing you out...</div>
  }

*/

  if (auth.error) {
    console.log(auth.error.message);

    return (
      <AuthError
        msg={auth.error.message}
        onAction={() => {
          auth.signoutRedirect();
        }}
      />
    );
  } else {
    return auth.isAuthenticated && !isLoading ? <>{children}</> : null;
  }
};

const SimpleSignOnProvider: FC<IProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const nav = useNavigate();

  async function init() {
    await tokenService.initStores();
    setIsLoading(false);
  }

  useEffect(() => {
    if (!tokenService.authenticated) {
      localStore.clear();
      nav('/login');
    } else {
      //user уже залогинен надо подгрузить данные
      init();
    }
  }, [tokenService.authenticated]);

  return !isLoading ? <>{children}</> : null;
};

const AuthProvider: FC<IProps> = ({ children }) => {
  return getConfig().useOidc ? (
    <OidcSignOnProvider>{children}</OidcSignOnProvider>
  ) : (
    <SimpleSignOnProvider>{children}</SimpleSignOnProvider>
  );
};

export default observer(AuthProvider);
