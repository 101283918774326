
import {
  DataTable,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react';
import { formatDate } from '@helpers/date';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import { personService } from '@services';
import { OnboardingFiltered, OnboardingsFilter } from '@services/models/person';
import { useLoader, usePagination } from '@src/library/utils/hooks';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  filters: OnboardingsFilter
  className?: string
}

type SortByType = Parameters<typeof personService.fetchOnboardingsForAnalytics>[3]

const OnboardingsListTable: FC<IProps> = ({ filters, className }) => {
  const [dataList, setDataList] = useState<OnboardingFiltered[]>([]);
  const pagination = usePagination<SortByType>();

  const loadData = async () => {
    const response = await personService.fetchOnboardingsForAnalytics(
      filters,
      pagination.limit,
      pagination.offset,
      pagination.sortBy,
      pagination.sortOrder,
    );

    if (response.isSuccess && response.data?.data) {
      setDataList(response.data.data);
      pagination.setPagingCount(response.data.paging?.count ?? 0);
    } else {
      setDataList([]);
      pagination.setPagingCount(0);
    }
  };

  const isLoading = useLoader(async () => {
    await loadData();
  }, [filters, pagination.page, pagination.sortBy, pagination.sortOrder]);

  const headers: Array<{
    key: keyof OnboardingFiltered
    header: string
    isSortable: boolean
  }> = [
    { key: 'departmentName', header: 'Подразделение', isSortable: true },
    { key: 'personNickName', header: 'Сотрудник', isSortable: true },
    { key: 'personEndDate', header: 'Дата увольнения сотрудника', isSortable: false },
    { key: 'startDate', header: 'Дата начала', isSortable: true },
    { key: 'endDate', header: 'Дата окончания', isSortable: true },
    { key: 'dueTo', header: 'Дата закрытия', isSortable: true },
    { key: 'hrpp', header: 'HRPP', isSortable: true },
    { key: 'mentor', header: 'Ментор', isSortable: true },
    { key: 'planLink', header: 'Ссылка на план', isSortable: true },
    { key: 'author', header: 'Автор', isSortable: true },
    { key: 'createDate', header: 'Дата создания', isSortable: false },
  ];

  const rows = dataList.map((item, index) => ({
    id: `${item?.id}` || [index, item.personNickName].join('_'),
    departmentName: item.departmentName,
    personNickName: item.personNickName,
    personEndDate: formatDate(item.personEndDate),
    startDate: formatDate(item.startDate),
    endDate: formatDate(item.endDate),
    dueTo: formatDate(item.dueTo),
    hrpp: item.hrpp,
    mentor: item.mentor,
    planLink: item.planLink,
    author: item.author,
    createDate: formatDate(item.createDate),
  }));

  return (
    <WithLoaderWrapper isLoading={isLoading} mode={'update'} className={className}>
      <DataTable headers={headers} rows={rows} isSortable={true} size="lg">
        {({
          rows,
          headers,
          getTableProps,
          getTableContainerProps,
          getHeaderProps,
          getRowProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        isSortHeader={pagination.sortBy === header.key}
                        isSortable={header?.isSortable}
                        sortDirection={pagination.sortOrder?.toUpperCase()}
                        onClick={() => pagination.handleHeaderClick(header.key)}
                        key={header?.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length > 0 ? (
                    rows.map((row) => (
                      <TableRow {...getRowProps({ row })}>
                        {row.cells.map((cell) => {
                          const cellKey = [row.id, cell.id].join('_');

                          if (['mentor', 'hrpp'].includes(cell.info.header)) {
                            return (
                              <TableCell key={cellKey}>
                                <Link to={'/user-profile/' + cell.value}>{cell.value}</Link>
                              </TableCell>
                            );
                          } else if (cell.info.header === 'planLink') {
                            return (
                              <TableCell key={cellKey}>
                                <a href={cell.value} target="_blank" rel="noreferrer">
                                  {cell.value}
                                </a>
                              </TableCell>
                            );
                          }

                          return <TableCell key={cellKey}>{cell.value}</TableCell>;
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="mt-20">
                      <TableCell colSpan={headers.length}>Нет записей</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      <PaginationNav
        page={pagination.page}
        totalItems={pagination.totalItems}
        onChange={pagination.setPage}
        itemsShown={pagination.itemsShown}
      />
    </WithLoaderWrapper>
  );
};

export default observer(OnboardingsListTable);
