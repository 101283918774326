
import { USER_ROLES_MAPPING } from '@library/utils/constants';
import { HealthCheck } from '@services/models/health-check';
import dayjs from 'dayjs';

export function getUserRoleLabel(roleId: string) {
  const role = USER_ROLES_MAPPING.find((x) => x.id === roleId);

  return role?.label || roleId;
}

export function isEqualLogin(login1: string, login2: string) {
  return _.toLower(login1) === _.toLower(login2);
}

export function wordForNumeric(value: number, words: string[]) {
  let num = value % 100;

  if (num > 19) {
    num = num % 10;
  }

  switch (num) {
    case 1:
      return words[0];
    case 2:
    case 3:
    case 4:
      return words[1];
    default:
      return words[2];
  }
}

export function getFileExtension(filename: string) {
  const parts = filename.split('.');

  return parts.length > 1 ? _.toLower(_.last(parts)) : '';
}

export function getInlineHealthCheckText(hc: HealthCheck) {
  return `${hc.departmentName}/${dayjs(hc.startDate).startOf('quarter').format('YYYY-MM-DD')}/${
    hc.code
  }`;
}
