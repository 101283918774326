
import {
  Button,
  ButtonSet,
  FileUploaderButton,
  FileUploaderItem,
  Select,
  SelectItem,
  Stack,
} from '@carbon/react';
import { getFilesDetails } from '@helpers/other';
import { zodResolver } from '@hookform/resolvers/zod';
import { $catalog, $loader, $modal, $user } from '@library/providers/StoreProvider';
import AutoSizedTextArea from '@library/ui/textarea/AutoSizedTextArea';
import Title from '@library/ui/title/Title';
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals';
import CustomAlertModal from '@library/utils/modals/CustomAlertModal';
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store';
import { showErrorAlert } from '@library/utils/toast';
import { notificationsService } from '@services';
import { FeedbackData, FeedbackDataTypeEnum } from '@services/models/notifications';
import { formErrors } from '@src/library/utils/constants';
import { usePersonsFilter } from '@src/modules/feedback';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMatch, useNavigate } from 'react-router-dom';
import { z } from 'zod';

type IProps = {
  _core: IModalItemCore
}

const MAX_FILE_SIZE = 5; // mb

const schema = z.object({
  author: z.string(),
  personNickName: z.string().min(1, { message: formErrors.required }),
  type: z.nativeEnum(FeedbackDataTypeEnum),
  subject: z.string().min(1, { message: formErrors.required }),
  description: z.string().min(1, { message: formErrors.required }),
  attachment: z.array(
    z.instanceof(File).refine((file) => file.size <= MAX_FILE_SIZE * 1024 * 1024, {
      message: `Размер файла должен быть меньше ${MAX_FILE_SIZE}MB`,
    }),
  ),
});

export type IFormProperties = z.infer<typeof schema>

const FeedbackFormModal: FC<IProps> = ({ _core }) => {
  const nav = useNavigate();
  const isFeedbackListPage = useMatch('/feedback');

  /*
   * Form controller
   */
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormProperties>({
    defaultValues: {
      author: $user.loggedInUser.preferredUsername,
      personNickName: $user.loggedInUser.preferredUsername,
      type: $catalog.feedbackType[0]?.value as unknown as FeedbackDataTypeEnum,
      subject: '',
      description: '',
      attachment: [],
    },
    resolver: zodResolver(schema),
  });

  /*
   * Data loading
   */
  const { personsList, isPersonsLoading } = usePersonsFilter();
  const isLoading = isPersonsLoading;

  /*
   * Prepared values
   */
  const values = watch();

  // useEffect(() => {
  //   if (!isLoading) {
  //     console.log('values', values)
  //     console.log('errors', errors)
  //   }
  // }, [isLoading, values])

  /*
   * Field handlers
   */
  const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    setValue('attachment', [...values.attachment, ...newFiles]);
  };

  const handleAttachmentDelete = (
    event: React.SyntheticEvent<HTMLElement>,
    opts: { uuid: string },
  ) => {
    setValue(
      'attachment',
      values.attachment.filter((x, index) => index.toString() !== opts.uuid),
    );
  };

  const loaderName = 'user-feedback-form';
  const onSubmit = $loader.registerHandler(loaderName, async (data: IFormProperties) => {
    const files = (await getFilesDetails(data.attachment)) as unknown as FeedbackData['attachment'];

    const payload: FeedbackData = {
      author: data.author,
      personNickName: data.personNickName,
      type: data.type,
      subject: data.subject,
      description: data.description,
      attachment: files?.filter((x) => x.file),
    };

    const formData = new FormData();

    formData.append('author', payload.author!);
    formData.append('personNickName', payload.personNickName!);
    formData.append('type', payload.type!);
    formData.append('subject', payload.subject!);
    formData.append('description', payload.description!);

    payload.attachment!.forEach((item, index) => {
      formData.append(`attachment[${index}][file]`, item.file!);
      formData.append(`attachment[${index}][fileName]`, item.fileName!);
      formData.append(`attachment[${index}][fileType]`, item.fileType!);
    });

    const response = await notificationsService.addFeedback(formData);

    if (response.isSuccess) {
      _core.hide();

      const newFeedback = response.data;

      if (newFeedback?.id) {
        $modal.add(CustomAlertModal, {
          title: `Обращение №${newFeedback?.id} создано`,
          message: 'Вы можете отслеживать статус обращения на странице "Список обращений".',
          onDone: () => nav('/feedback/'),
          onDoneText: isFeedbackListPage ? null : 'Посмотреть',
          onCancelText: 'Закрыть',
        });
      }
    } else {
      showErrorAlert(formErrors.somethingWentWrong);
    }
  });

  return (
    <ModalBase>
      <ModalHeader>
        <Title size="h2">Создать обращение</Title>
      </ModalHeader>
      <ModalBody isLoading={isLoading} className="pt-20 pb-20">
        <form id={loaderName} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={6}>
            <Select
              id="personNickName"
              labelText="Затронутый пользователь"
              defaultValue={values.personNickName}
              {...register('personNickName')}
              invalid={!!errors.personNickName}
              invalidText={<>{errors.personNickName?.message}</>}
            >
              {personsList.map((x) => (
                <SelectItem key={x.id} text={x.nickName!} value={x.nickName} />
              ))}
            </Select>
            <Select
              id="type"
              labelText="Тип обращения"
              {...register('type')}
              invalid={!!errors.type}
              invalidText={<>{errors.type?.message}</>}
            >
              {$catalog.feedbackType.map((x) => (
                <SelectItem key={x.value} text={x.shortName} value={x.value} />
              ))}
            </Select>
            <AutoSizedTextArea
              labelText="Краткое описание"
              enableCounter={true}
              // maxCount={250}
              id="subject"
              invalid={!!errors.subject}
              invalidText={<>{errors.subject?.message}</>}
              {...register('subject')}
            />
            <AutoSizedTextArea
              labelText="Описание"
              enableCounter={true}
              rows={5}
              // maxCount={3000}
              id="description"
              invalid={!!errors.description}
              invalidText={<>{errors.description?.message}</>}
              {...register('description')}
            />
            <div>
              <Title size={'h5'}>Вложения</Title>
              <div className="cds--label-description mb-10">
                Максимальный размер файла {MAX_FILE_SIZE}MB.
              </div>
              <FileUploaderButton
                labelText="Добавить файл"
                buttonKind="secondary"
                size={'sm'}
                multiple={true}
                className={'mb-10'}
                disableLabelChanges={true}
                id="attachment"
                name="attachment"
                onChange={handleAttachmentChange}
              />
              {values.attachment.map((attachment, index) => (
                <FileUploaderItem
                  key={[attachment.name, index].join('_')}
                  name={attachment.name}
                  iconDescription="Удалить файл"
                  status={'edit'}
                  size={'sm'}
                  invalid={!!errors.attachment?.[index]?.message}
                  errorSubject={errors.attachment?.[index]?.message}
                  onDelete={handleAttachmentDelete}
                  uuid={`${index}`}
                />
              ))}
            </div>
          </Stack>
        </form>
      </ModalBody>
      {!isLoading && (
        <ModalFooter>
          <ButtonSet>
            <Button kind="tertiary" onClick={_core.hide}>
              Отмена
            </Button>
            <Button form={loaderName} type="submit" disabled={$loader.isRunHandler(loaderName)}>
              {$loader.isRunHandler(loaderName) ? 'Загрузка...' : 'Отправить'}
            </Button>
          </ButtonSet>
        </ModalFooter>
      )}
    </ModalBase>
  );
};

export default observer(FeedbackFormModal);
