import React, { FC, useEffect } from 'react';
import {
  DataTable,
  DatePickerInput,
  OverflowMenuItem,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from '@carbon/react';
//@ts-ignore
import { OverflowMenu } from '@carbon/react/lib/components/OverflowMenu';
import 'dayjs/locale/ru';


import { currentQuarter, formatDate, formatQuarter } from '@helpers/date';
import { useRouterSearchParams } from '@helpers/router';
import { $catalog, $filter, $loader, $modal, $user } from '@library/providers/StoreProvider';
import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import CustomAlertModal from '@library/utils/modals/CustomAlertModal';
import { showErrorAlert } from '@library/utils/toast';
import { devPlanService } from '@services';
import { HealthCheck, HealthCheckIndex } from '@services/models/health-check';
import { formErrors } from '@src/library/utils/constants';
import { useLoader, usePagination } from '@src/library/utils/hooks';
import HealthCheckFormModal from '@src/modules/healthcheck/ui/HealthCheckFormModal';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { indexEnumMetaActive } from '../model/helpers/HealthCheckIndexesMeta';
import { usePeriodDates } from '../model/hooks/HealthChecksTab.hook';

import styles from './HealthChecksTab.module.scss';

type HealthChecksTabProps = {
  startQDate?: Date
  showSelector?: boolean
}

const HealthChecksTab: FC<HealthChecksTabProps> = observer(
  ({ startQDate, showSelector = false }) => {
    const [healthCheckList, setHealthCheckList] = React.useState<HealthCheck[]>([]);
    const searchParams = useRouterSearchParams();
    const hcId = searchParams.get('id');

    const { periodStartDate, periodEndDate, periodError } = usePeriodDates();

    const {
      page,
      setPage,
      totalItems,
      setPagingCount,
      sortBy,
      sortOrder,
      handleHeaderClick,
      limit,
      offset,
    } = usePagination({
      defaultSortBy: 'code',
      defaultSortOrder: 'desc',
      dependencies: [startQDate, $filter.selectedCodes, periodStartDate, periodEndDate],
    });

    useEffect(() => {
      if (hcId) {$modal.add(HealthCheckFormModal, { id: hcId });}
    }, [hcId]);

    const selectedDepartments = $filter.selectedCodes;

    async function loadData(
      startQDate: Date = currentQuarter(),
      selectedDepartments: string[],
    ) {
      const qStart = dayjs(startQDate);
      const isPeriodSelected = periodStartDate || periodEndDate;

      if (isPeriodSelected && periodError) {
        return;
      }

      const response = await devPlanService.fetch({
        startDate: isPeriodSelected ? undefined : qStart.startOf('quarter').format('YYYY-MM-DD'),
        endDate: isPeriodSelected ? undefined : qStart.endOf('quarter').format('YYYY-MM-DD'),
        manager: $user.manager,
        departments: selectedDepartments,
        periodStartDate: formatDate(periodStartDate, { format: 'isoDate' }),
        periodEndDate: formatDate(periodEndDate, { format: 'isoDate' }),
        limit,
        offset,
        sortBy,
        sortOrder,
      });

      if (response.isSuccess && response.data?.healthCheck) {
        setHealthCheckList(response.data?.healthCheck);
        setPagingCount(response.data?.paging.count);
      }
    }

    const isLoading = useLoader(async () => {
      await loadData(startQDate, selectedDepartments);
    }, [startQDate, selectedDepartments, periodStartDate, periodEndDate, offset, sortBy, sortOrder]);

    useEffect(() => {
      const ids = ['hc-create-or-update-form'];
      const subIds = $loader.subscribe(ids, () => loadData(startQDate, selectedDepartments));

      return () => $loader.unsubscribe(ids, subIds);
    }, []);

    const reasonMap = $catalog.healthCheckReasonMap;

    let indexHeaders = Object.keys(indexEnumMetaActive).map((key) => ({
      key,
      header: indexEnumMetaActive[key].title,
      tablePosition: indexEnumMetaActive[key].tablePosition,
      isSortable: false,
    }));

    indexHeaders = _.orderBy(indexHeaders, 'tablePosition');

    const headers = [
      { key: 'code', header: 'Код', isSortable: true },
      { key: 'departmentName', header: 'Подразделение', isSortable: true },
      { key: 'startDate', header: 'За период', isSortable: true },
      { key: 'reason', header: 'Причина проведения', isSortable: true },
    ].concat(indexHeaders);

    const rows = healthCheckList
      .map((x: HealthCheck) => {
        const indexes: { [char: string]: string } = {};

        x.indexList?.forEach((idx: HealthCheckIndex) => {
          if (idx.value) {
            indexes[idx.code!] = `${idx.value}%`;
          }
        });

        return {
          ...x,
          ...indexes,
          code: (
            <a
              className="cursor-pointer"
              onClick={() => {
                handleOpen(x.id!, false);
              }}
            >
              {x.code}
            </a>
          ),
          startDate: formatQuarter(x.startDate),
          reason: reasonMap.get(x.reason ?? 0),
          id: x.id!,
        };
      })
      .filter((x) => x.id);

    const onDateChange = (dates: any, paramName: string) => {
      if (dates.length === 0) {
        searchParams.remove(paramName);
      } else {
        searchParams.set(paramName, formatDate(dates[0], { format: 'isoDate' }));
      }
    };

    function handleOpen(id: string, forEdit: boolean) {
      $modal.add(HealthCheckFormModal, { id, forEdit });
    }

    async function handleDelete(id: string) {
      $modal.add(CustomAlertModal, {
        title: 'Удаление healthcheck',
        message: `Вы точно хотите удалить healthcheck ${id}?`,
        onDone: async () => {
          const response = await devPlanService.deleteHealthCheck(id);

          if (response.isSuccess) {
            loadData(startQDate, selectedDepartments);
          } else {
            const msg =
              response.response?.status === 403
                ? formErrors.forbidden
                : formErrors.somethingWentWrong;
            showErrorAlert(msg);
          }
        },
        onDoneButtonType: 'danger',
      });
    }

    const hasEditPermissions = $user.hasPerm('PERM_HEALTHCHECK_FULL');

    return (
      <>
        <DataTable headers={headers} rows={rows} isSortable={false}>
          {(params) => {
            const { rows, headers, getTableProps, getHeaderProps, getRowProps, getToolbarProps } =
              params;

            return (
              <TableContainer>
                {showSelector && (
                  <TableToolbar className={styles.header} {...getToolbarProps()}>
                    <TableToolbarContent className={styles.toolbar}>
                      <HcmDatePicker
                        value={periodStartDate}
                        onChange={(dates) => onDateChange(dates, 'periodStartDate')}
                        onClose={(dates) => onDateChange(dates, 'periodStartDate')}
                        className={styles.toolbarItem}
                      >
                        <DatePickerInput
                          placeholder="dd.mm.yyyy"
                          labelText=""
                          size="sm"
                          invalid={!!periodError}
                          invalidText={periodError}
                          hideLabel={true}
                          id="startDate"
                        />
                      </HcmDatePicker>
                      <HcmDatePicker
                        value={periodEndDate}
                        onChange={(dates) => onDateChange(dates, 'periodEndDate')}
                        onClose={(dates) => onDateChange(dates, 'periodEndDate')}
                      >
                        <DatePickerInput
                          placeholder="dd.mm.yyyy"
                          labelText=""
                          size="sm"
                          hideLabel={true}
                          id="endDate"
                        />
                      </HcmDatePicker>
                    </TableToolbarContent>
                  </TableToolbar>
                )}
                <WithLoaderWrapper isLoading={isLoading}>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header: any) => (
                          <TableHeader
                            {...getHeaderProps({ header })}
                            isSortable={header.isSortable}
                            onClick={() => handleHeaderClick(header.key)}
                            isSortHeader={sortBy === header.key}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                        <TableHeader key="action" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow {...getRowProps({ row })}>
                          {row.cells.map((cell, index) => (
                            <TableCell key={[cell.id, index].join('_')}>{cell.value}</TableCell>
                          ))}
                          <TableCell key={'action:' + row.id}>
                            <OverflowMenu aria-label="overflow-menu" flipped={true}>
                              <OverflowMenuItem
                                itemText="Просмотр"
                                onClick={(e) => handleOpen(row.id, false)}
                              />
                              {hasEditPermissions && (
                                <>
                                  <OverflowMenuItem
                                    itemText="Редактировать"
                                    onClick={() => handleOpen(row.id, true)}
                                  />
                                  <OverflowMenuItem
                                    hasDivider={true}
                                    isDelete={true}
                                    itemText="Удалить"
                                    onClick={() => handleDelete(row.id)}
                                  />
                                </>
                              )}
                            </OverflowMenu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </WithLoaderWrapper>
              </TableContainer>
            );
          }}
        </DataTable>
        <PaginationNav
          className="pagination"
          page={page}
          totalItems={totalItems}
          onChange={setPage}
        />
      </>
    );
  },
);
export default HealthChecksTab;
