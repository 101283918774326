import Title from '@library/ui/title/Title';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';



type IProps = {
  title?: string
  message?: string
}

const InnerErrorPage: FC<IProps> = ({ title = '404', message = 'Страница отсутствует' }) => {
  return (
    <>
      <Title size="h1">{title}</Title>
      <p>{message}</p>
    </>
  );
};

export default observer(InnerErrorPage);
