
import {
  Button,
  DataTable,
  ExpandableSearch,
  /* @ts-ignore */
  OverflowMenu,
  OverflowMenuItem,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react';
import { $loader, $modal } from '@library/providers/StoreProvider';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import { permissionsService } from '@services';
import { UsersRolesList, UsersRolesListUserRoles } from '@services/models/userPermissions';
import { useLoader, usePagination } from '@src/library/utils/hooks';
import { EditCustomManagerModal, RolesAddFormModal, ViewRoleModal } from '@src/modules/roles';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import styles from './RolesPage.module.scss';


const RolesPage = () => {
  const [usersList, setUsersList] = useState<UsersRolesList[] | []>([]);
  const pagination = usePagination({ defaultSortBy: 'userLogin', defaultSortOrder: 'asc' });

  const getUsersRolesList = async (
    searchConditions?: { searchValue: string; searchMode: string },
    initial?: boolean,
  ) => {
    const response = await permissionsService.fetchUserRoles({
      limit: pagination.limit,
      offset: pagination.offset,
      searchValue: searchConditions?.searchValue,
      searchMode: searchConditions?.searchMode,
      sortBy: pagination.sortBy,
      sortOrder: pagination.sortOrder,
    });

    if (response.isSuccess && response.data?.data) {
      if (initial) {
        setUsersList(response.data.data);
      } else {
        if (searchConditions) {
          setUsersList(response.data.data);
        } else {
          setUsersList((prev) => [...prev, ...response.data?.data!]);
        }
      }

      pagination.setPagingCount(response.data?.paging?.count ?? 0);
    }
  };

  const isLoading = useLoader(async () => {
    await getUsersRolesList(undefined, true);
  }, [pagination.sortBy, pagination.sortOrder, pagination.page]);

  useEffect(() => {
    const ids = ['roles-add-form', 'roles-edit-form'];
    const subIds = $loader.subscribe(ids, () => getUsersRolesList(undefined, true));

    return () => $loader.unsubscribe(ids, subIds);
  });

  const handleAdd = () => {
    $modal.add(RolesAddFormModal);
  };

  const handleEdit = (role: UsersRolesListUserRoles, nick: string) => {
    $modal.add(EditCustomManagerModal, { role, nick });
  };

  const handleViewRole = (role: UsersRolesListUserRoles, nick: string) => {
    $modal.add(ViewRoleModal, { role, nick });
  };

  const handleDeleteRole = async (id: string) => {
    const response = await permissionsService.deleteUserRole({ id });

    if (response.isSuccess) {
      const updatedData = usersList.map((item) => {
        const updatedUserRoles = item?.userRoles?.filter((role) => role.id !== id);

        return { ...item, userRoles: updatedUserRoles };
      });
      setUsersList(updatedData);
    }
  };

  const debouncedHandleSearch = _.debounce((query) => {
    if (query && query?.length > 2) {
      const searchConditions = {
        searchValue: query,
        searchMode: 'CONTAINS',
      };
      pagination.setPage(0);
      getUsersRolesList(searchConditions);
    }

    if (!query) {
      pagination.setPage(0);
      getUsersRolesList(undefined, true);
    }
  }, 300);

  const headers = [
    { key: 'userLogin', header: 'Ник', isSortable: true },
    { key: 'role', header: 'Роль' },
    { key: 'department', header: 'Подразделения/сотрудники' },
    { key: 'source', header: 'Источник назначения роли' },
    { key: 'action', header: '' },
  ];

  const options = (role: UsersRolesListUserRoles, nick: string) => (
    <OverflowMenu aria-label="overflow-menu" flipped={true}>
      {role?.role !== 'MANAGERCUSTOM' && (
        <OverflowMenuItem itemText="Просмотр" onClick={() => handleViewRole(role, nick)} />
      )}
      {role?.role === 'MANAGERCUSTOM' && (
        <OverflowMenuItem
          className={styles.option}
          itemText="Просмотр и редактирование"
          onClick={() => handleEdit(role, nick)}
        />
      )}
      {role?.source === 'HCM' && (
        <OverflowMenuItem
          itemText="Удалить"
          hasDivider={true}
          isDelete={true}
          onClick={() => role?.id && handleDeleteRole(role?.id)}
        />
      )}
    </OverflowMenu>
  );

  const rows = useMemo(() => {
    return usersList
      .flatMap((user, index) => {
        return (
          user?.userRoles?.map((role, roleIndex) => ({
            id: `${index}-${roleIndex}`,
            userLogin: roleIndex === 0 ? user.userLogin : '',
            role: role.roleName,
            department:
              role?.subPersons && role?.subPersons?.length > 0 ? (
                <span className={styles.col}>{role?.subPersons?.join(', ')}</span>
              ) : (
                <span className={styles.col}>{role?.subDepartmentsName?.join(', ')}</span>
              ),
            source: role.source,
            action: options(role, user?.userLogin),
          })) || []
        );
      })
      .filter((row) => row !== undefined);
  }, [usersList]);

  return (
    <>
      <div className={classNames(styles.filters)}>
        <div className={styles.filtersItem}>
          <ExpandableSearch
            labelText=""
            size="lg"
            placeholder="Поиск..."
            isExpanded={true}
            className={styles.search}
            onChange={(e) => debouncedHandleSearch(e.target.value)}
          />
        </div>
        <div className={classNames(styles.filtersItem, styles.lastButton)}>
          <Button size="lg" onClick={handleAdd}>
            Назначить роль
          </Button>
        </div>
      </div>
      <WithLoaderWrapper
        isLoading={isLoading}
        mode={'update'}
        className={classNames(styles.table, 'mt-20')}
      >
        <DataTable headers={headers} rows={rows} isSortable={false}>
          {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => {
            return (
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        isSortHeader={pagination.sortBy === header.key}
                        isSortable={header?.isSortable}
                        sortDirection={pagination.sortOrder?.toUpperCase()}
                        onClick={() => pagination.handleHeaderClick(header.key)}
                        key={header?.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow {...getRowProps({ row })} key={'row' + row?.id}>
                      {row.cells.map((cell) => (
                        <TableCell key={'cell ' + cell.id}>{cell.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            );
          }}
        </DataTable>
        <PaginationNav
          page={pagination.page}
          totalItems={pagination.totalItems}
          onChange={pagination.setPage}
          itemsShown={pagination.itemsShown}
        />
      </WithLoaderWrapper>
    </>
  );
};

export default observer(RolesPage);
