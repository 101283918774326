
import {
  Column,
  DataTable,
  DatePickerInput,
  PaginationNav,
  Row,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  Tag,
} from '@carbon/react';
import { formatPeriod, getDurationInDays } from '@helpers/date';
import LayoutLkPageWrapper from '@library/layouts/LayoutLkPageWrapper';
import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker';
import TablePlaceholder from '@library/ui/tablePlaceHolder/TablePlaceholder';
import { personService } from '@services';
import { PersonAbsence } from '@services/models/person';
import { useStore } from '@src/library/providers/StoreProvider';
import { useLoader, usePagination } from '@src/library/utils/hooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

const statusColorMap: {
  [key: string]:
    | 'high-contrast'
    | 'red'
    | 'magenta'
    | 'purple'
    | 'blue'
    | 'cyan'
    | 'teal'
    | 'green'
    | 'gray'
    | 'cool-gray'
    | 'warm-gray'
    | 'outline'
    | undefined
} = {
  Создано: 'blue',
  Отказано: 'red',
  Предоставлено: 'green',
  Согласовано: 'warm-gray',
  Утверждено: 'purple',
};

const AbsencePage = () => {
  const { $cabinet } = useStore();

  const user = $cabinet.selectedLogin;
  const [personAbsence, setPersonAbsence] = useState<PersonAbsence[]>([]);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, 'year'));

  const pagination = usePagination({ dependencies: [startDate, endDate] });

  const isLoading = useLoader(async () => {
    const absenceStartDate = startDate.format('YYYY-MM-DD');
    const absenceEndDate = endDate.format('YYYY-MM-DD');

    if ($cabinet.canSeeAbsence) {
      const response = await personService.fetchAbsence(user, {
        absenceStartDate,
        absenceEndDate,
        limit: pagination.limit,
        offset: pagination.offset,
        sortBy: pagination.sortBy ?? 'absenceStartDate',
        sortOrder: pagination.sortOrder,
      });

      if (response.isSuccess && response.data.personAbsence) {
        setPersonAbsence(response.data.personAbsence);
        pagination.setPagingCount(response.data.paging?.count ?? 0);
      }
    }
  }, [
    startDate,
    endDate,
    pagination.limit,
    pagination.offset,
    pagination.sortBy,
    pagination.sortOrder,
  ]);

  const handleStartDateChange = (dates: any) => {
    setStartDate(dayjs(dates[0]));
  };

  const handleEndDateChange = (dates: any) => {
    setEndDate(dayjs(dates[0]));
  };

  const headers = [
    { key: 'id', header: '№' },
    { key: 'absenceStartDate', header: 'Период', isSortable: true },
    { key: 'reason', header: 'Причина', isSortable: true },
    { key: 'status', header: 'Статус', isSortable: true },
  ];

  const rows = useMemo(
    () =>
      personAbsence?.map((x, index) => ({
        id: (index + 1).toString(),
        absenceStartDate: `${formatPeriod(x.startDate, x.endDate)} (${getDurationInDays(
          x.startDate,
          x.endDate,
        )})`,
        reason: x.reason,
        status: <Tag type={statusColorMap[x.status!]}>{x.status}</Tag>,
      })),
    [personAbsence],
  );

  return (
    <LayoutLkPageWrapper isLoading={isLoading}>
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({
          rows,
          headers,
          getTableProps,
          getHeaderProps,
          getRowProps,
          getTableContainerProps,
          getToolbarProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()} className="customCds">
              <TableToolbar {...getToolbarProps()}>
                <Row>
                  <Column className="mb-10">
                    <HcmDatePicker onChange={handleStartDateChange}>
                      <DatePickerInput
                        id="startDate"
                        size="sm"
                        labelText="Начало"
                        defaultValue={startDate?.format('DD.MM.YYYY')}
                        hideLabel={false}
                      />
                    </HcmDatePicker>
                  </Column>
                  <Column className="mb-10">
                    <HcmDatePicker onChange={handleEndDateChange}>
                      <DatePickerInput
                        size="sm"
                        defaultValue={endDate?.format('DD.MM.YYYY')}
                        id="endDate"
                        hideLabel={false}
                        labelText="Окончание"
                        placeholder="окончание"
                      />
                    </HcmDatePicker>
                  </Column>
                </Row>
              </TableToolbar>

              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        isSortable={header.isSortable}
                        onClick={() => pagination.handleHeaderClick(header.key)}
                        isSortHeader={pagination.sortBy === header.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0 &&
                    rows.map((row) => (
                      <TableRow {...getRowProps({ row })} onClick={() => {}}>
                        {row.cells.map((cell) => (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {rows.length === 0 && (
                <TablePlaceholder text="Записи об отсутствии сотрудника не найдены" />
              )}
            </TableContainer>
          );
        }}
      </DataTable>
      <PaginationNav
        className="pagination"
        page={pagination.page}
        totalItems={pagination.totalItems}
        onChange={pagination.setPage}
        itemsShown={pagination.itemsShown}
      />
    </LayoutLkPageWrapper>
  );
};

export default observer(AbsencePage);
