import { apiRequest } from '@services/api';
import { Task } from '@services/models/task';

import { AppConfig } from './config';
import { TaskFilter } from './models/dashboard';

export type ITaskStatuses = 'ToDo' | 'Done' | 'InProgress'

class HumanTaskService {
  baseUrl: string

  constructor(config: AppConfig) {
    this.baseUrl = config.taskServiceUrl;
  }

  async fetchTasks(params: {
    person?: string
    departments?: string | string[]
    taskStatuses?: ITaskStatuses | ITaskStatuses[]
    author?: string
    startDate?: string // date
    endDate?: string // date
    causeType?: string
    causeObjectId?: string
    functionalManager?: string
    manager?: string
    offset?: number
    limit?: number
    sortBy?: string
    sortOrder?: string
    searchValue?: string
  }) {
    const {
      person,
      departments,
      taskStatuses,
      author,
      startDate,
      endDate,
      causeType,
      causeObjectId,
      functionalManager,
      manager,
      offset = 0,
      limit = 1000,
      sortBy,
      sortOrder,
      searchValue,
    } = params;

    return apiRequest({
      url: this.baseUrl + '/tasks',
      method: 'GET',
      data: {
        person,
        departments,
        taskStatuses,
        author,
        startDate,
        endDate,
        causeType,
        causeObjectId,
        functionalManager,
        manager,
        offset,
        limit,
        sortBy,
        sortOrder,
        searchValue,
      },
    });
  }

  async createTask(params: any) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + '/tasks',
      data: params,
    });
  }

  async updateTask(params: any) {
    return apiRequest({
      method: 'PUT',
      url: this.baseUrl + `/tasks/${params.id}`,
      data: params,
    });
  }

  async updateTaskStatus(params: { id: string; code: string }) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + `/tasks/${params.id}/status`,
      data: params,
    });
  }

  async get(id: string) {
    return apiRequest<Task>({
      url: this.baseUrl + '/tasks/' + id,
      method: 'GET',
    });
  }

  async deleteTask(id: string) {
    return apiRequest({
      method: 'DELETE',
      url: this.baseUrl + `/tasks/${id}`,
    });
  }

  async getComments(params: { id: string }) {
    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/tasks/${params.id}/comments`,
      data: params,
    });
  }

  async addComment(params: { id: string; commentText: string; mentionedList: string[] }) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + `/tasks/${params.id}/comments`,
      data: params,
    });
  }

  async getWatchers(params: { id: string }) {
    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/tasks/${params.id}/watchers`,
      data: params,
    });
  }

  async addWatcher(params: { id: string; watcher: string }) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + `/tasks/${params.id}/watcher`,
      data: {
        person: params.watcher,
      },
    });
  }

  async removeWatcher(params: { id: string; watcherId: string }) {
    return apiRequest({
      method: 'DELETE',
      url: this.baseUrl + `/tasks/${params.id}/watchers/${params.watcherId}`,
    });
  }

  async getTaskForAnalytics(
    options: TaskFilter,
    limit: number,
    offset: number,
    sortBy?: string,
    sortOrder?: string,
  ) {
    // todo: use params (for example: fetchOnboardingsForAnalytics)
    const defaultUrl = `/tasksfiltered?offset=${offset}&limit=${limit}`;
    const sort = !!sortBy && !!sortOrder && `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    const url = sort ? defaultUrl + sort : defaultUrl;

    return apiRequest({
      method: 'POST',
      url: this.baseUrl + url,
      data: options,
    });
  }
}

export default HumanTaskService;
