import dayjs from 'dayjs';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const generateId = (): string => uuidv4();

export function valueOrPlaceholder(value: any, placeholder?: any) {
  return !_.isEmpty(_.trim(value)) ? value : placeholder || '—';
}

export function valueOrDash(value: any) {
  return valueOrPlaceholder(value, '—');
}

export function isJson(str: string) {
  try {
    JSON.parse(str);

    return true;
  } catch (e) {
    return false;
  }
}

export function isAbsoluteEmpty(value: any) {
  if (_.isArray(value) || _.isObject(value)) {
    return _.isEmpty(value);
  }

  return (value !== 0 && !value) || String(value).trim() === '';
}

export function isUrl(url?: string): boolean {
  return (url || '').startsWith('http');
}

export function parseJwt(token: any) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  if (isJson(jsonPayload)) {
    return JSON.parse(jsonPayload);
  }

  return {};
}

export function nativeScrollTo(id = '', params = {}) {
  if (id) {
    const el = document.querySelector('#' + id);

    if (el) {
      el.scrollIntoView(params);
    }
  }
}

function getStorageInstance(storage: any) {
  const instance = {
    _parsePath(pp: string) {
      const [name, ...path] = _.toPath(pp) || [];

      return { name, path };
    },
    get(pp: string, defaultValue?: any) {
      const { name, path } = this._parsePath(pp);
      let result = storage.getItem(name);

      if (typeof result === 'undefined' || result === 'undefined') {
        return defaultValue;
      }

      result = isJson(result) ? JSON.parse(result) : result;

      if (path.length > 0) {
        return _.get(result, path, defaultValue);
      }

      return result || defaultValue;
    },
    set(
      pp: string,
      payload: any,
      options?: {
        lifeTime?: number | undefined
        lifeTimePath?: string
      },
    ) {
      const { name, path } = this._parsePath(pp);
      let result;

      if (path.length > 0) {
        result = this.get(name, {});
        _.set(result, path, payload);
      } else {
        result = payload;
      }

      if (options?.lifeTime) {
        let expName = name;
        let expPath = path;

        if (options.lifeTimePath) {
          const expParsed = this._parsePath(options.lifeTimePath);
          expName = expParsed.name;
          expPath = expParsed.path;
        }

        const expData = {
          path: [expName, ...expPath].filter((x) => x).join('.'),
          exp: dayjs().add(options.lifeTime, 's'),
        };

        const existingTempData = this.get('tmp', []);
        const result = existingTempData.forEach((x: any) => x.path !== expData.path) || [];

        result.push(expData);
        storage.setItem('tmp', JSON.stringify(result));
      }

      storage.setItem(name, JSON.stringify(result));
    },
    remove(pp: string) {
      const { name, path } = this._parsePath(pp);
      let result;

      if (path.length > 0) {
        result = this.get(name, {});
        _.unset(result, path);
        storage.setItem(name, JSON.stringify(result));
      } else {
        storage.removeItem(name);
      }
    },
    clear() {
      storage.clear();
    },
    clearTemp() {
      const result: any = [];
      const existingTempData = this.get('tmp', []);
      existingTempData.forEach((x: any) => {
        if (dayjs().isBefore(x.exp) && this.get(x.path)) {
          result.push(x);
        } else {
          this.remove(x.path);
        }
      });

      if (result.length) {
        storage.setItem('tmp', JSON.stringify(result));
      } else {
        storage.removeItem('tmp');
      }
    },
  };

  instance.clearTemp();

  return instance;
}

export const localStore = getStorageInstance(localStorage);
export const sessionStore = getStorageInstance(sessionStorage);

export function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

export const toBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
  });
};

export const getFilesDetails = async (files: File[] = []) => {
  return await Promise.all(
    files.map(async (file) => {
      const encodeFile = ((await toBase64(file)) as string) || '';

      return {
        file: encodeFile.split(',')[1],
        fileName: file.name,
        fileType: file.type,
      };
    }),
  );
};

export type DownloadByData = {
  url?: string
  blob?: Blob
  base64Data?: {
    file: string | Blob
    fileType: string
  }
}

export type DownloadByParams = {
  fileName?: string
  mode?: 'newTab' | 'download'
}

export function downloadBy(
  { url = '', blob, base64Data }: DownloadByData,
  { fileName = 'filename', mode = 'download' }: DownloadByParams,
) {
  if (!url) {
    if (base64Data) {
      if (base64Data.file instanceof Blob) {
        blob = base64Data.file;
      } else {
        const binaryString = atob(base64Data.file);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        blob = new Blob([bytes], { type: base64Data.fileType });
      }
    }

    if (blob instanceof Blob) {
      url = URL.createObjectURL(blob);
    }
  }

  const link = document.createElement('a');

  link.href = url;

  if (mode === 'newTab') {
    link.target = '_blank';
  }

  if (mode === 'download') {
    link.download = fileName;
  }

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);

  if (url) {URL.revokeObjectURL(url);}
}
