


import { formatDate } from '@helpers/date';
import { isEqualLogin } from '@helpers/text.js';
import LayoutLkPageWrapper from '@library/layouts/LayoutLkPageWrapper';
import { useStore } from '@library/providers/StoreProvider';
import { humanTaskService } from '@services';
import { ITaskStatuses } from '@services/HumanTaskService';
import { useLoader, usePagination } from '@src/library/utils/hooks';
import TaskCountFrom from '@src/modules/TaskCountFrom/ui/TaskCountFrom';
import {
  useTaskAuthorFilter,
  useTaskPeriodFilter,
  useTaskStatusFilter,
} from '@src/modules/taskList';
import TasksList from '@src/modules/taskList/ui/TasksList';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

const TasksPage = () => {
  const { $cabinet } = useStore();
  const { selectedStatuses } = useTaskStatusFilter();
  const { startDate, endDate } = useTaskPeriodFilter();

  const { author } = useTaskAuthorFilter();

  const [taskList, setTaskList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');

  const paginationOptions = usePagination({
    defaultSortBy: 'code',
    defaultSortOrder: 'desc',
    dependencies: [selectedStatuses, startDate, endDate, author],
  });

  const { limit, offset, sortBy, sortOrder, setPagingCount } = paginationOptions;

  async function loadData() {
    const startDateParam = startDate ? formatDate(startDate, { format: 'isoDate' }) : undefined;
    const endDateParam = endDate ? formatDate(endDate, { format: 'isoDate' }) : undefined;

    const response = await humanTaskService.fetchTasks({
      person: $cabinet.selectedLogin,
      startDate: startDateParam,
      endDate: endDateParam,
      taskStatuses: selectedStatuses.map((x: any) => x.value) as ITaskStatuses[],
      author,
      limit,
      offset,
      sortBy,
      sortOrder,
      searchValue,
    });

    if (response.isSuccess) {
      setTaskList(response.data?.data ?? []);
      setPagingCount(response.data?.paging?.count ?? 0);
    }
  }

  const isLoading = useLoader(async () => {
    await loadData();
  });

  useEffect(() => {
    if (!isLoading) {
      loadData();
    }
  }, [selectedStatuses, startDate, endDate, author, offset, searchValue, sortBy, sortOrder]);

  function shouldShowProfileLink(cell: any) {
    return !isEqualLogin(cell.value, $cabinet.selectedLogin);
  }

  return (
    <LayoutLkPageWrapper isLoading={isLoading}>
      <TasksList
        loadData={loadData}
        taskList={taskList}
        showDepartment={false}
        shouldShowProfileLink={shouldShowProfileLink}
        shouldShowAddTaskButton={$cabinet.canAddTasks}
        paginationOptions={paginationOptions}
        onSearchChange={(v: string) => {
          setSearchValue(v);
        }}
      />
      {$cabinet.isSelfCard && <TaskCountFrom />}
    </LayoutLkPageWrapper>
  );
};

export default observer(TasksPage);
