

import { isEqualLogin } from '@helpers/text.js';
import { $user } from '@library/providers/StoreProvider';
import { formErrors } from '@library/utils/constants';
import { showErrorAlert } from '@library/utils/toast';
import { humanTaskService } from '@services';
import { TaskWatcher } from '@services/models/task';
import { useLoader, useLoaderSubscribe } from '@src/library/utils/hooks';
import { useMemo, useState } from 'react';

export const useTaskWatchers = (taskId: string) => {
  const [watchers, setWatchers] = useState<TaskWatcher[]>([]);

  async function loadWatchers(taskId: string) {
    const response = await humanTaskService.getWatchers({ id: taskId });

    if (response.isSuccess && response.data) {
      setWatchers(response.data?.data);
    }
  }

  const isWatchersLoading = useLoader(async () => {
    if (!taskId) {
      return;
    }

    await loadWatchers(taskId!);
  });

  const isWatching = useMemo(() => {
    return (
      watchers &&
      watchers.findIndex((x: any) => isEqualLogin(x.person, $user.loggedInUser.preferredUsername)) >
        -1
    );
  }, [watchers]);

  useLoaderSubscribe(
    'add-task-watcher',
    (event) => {
      addWatcher(event.newWatcher);
    },
    'afterFinish', //sync calback
  );

  useLoaderSubscribe(
    'remove-task-watcher',
    (event) => {
      removeWatcher(event.taskWatcher);
    },
    'afterFinish', //sync calback
  );

  async function addWatcher(nickName: string) {
    const response = await humanTaskService.addWatcher({ id: taskId!, watcher: nickName });

    if (response.isSuccess) {
      await loadWatchers(taskId!);
    } else {
      showErrorAlert(formErrors.somethingWentWrong);
    }
  }

  async function removeWatcher(id: string) {
    const response = await humanTaskService.removeWatcher({
      id: taskId!,
      watcherId: id,
    });

    if (response.isSuccess) {
      setWatchers((existing) => {
        const filtered = existing.filter((x) => {
          return id !== x.id;
        });

        return filtered;
      });
    } else {
      showErrorAlert(formErrors.somethingWentWrong);
    }
  }

  return { isWatchersLoading, watchers, isWatching, addWatcher, removeWatcher };
};
