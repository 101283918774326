
import { Add } from '@carbon/icons-react';
import { IconButton, Tag } from '@carbon/react';
import { $filter, $modal } from '@library/providers/StoreProvider';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import styles from './DepartmentSelect.module.scss';
import DepartmentsModal from './DepartmentsModal';

const DepartmentSelect = () => {
  function handleOpenModal() {
    $modal.add(DepartmentsModal, {
      onSelect: (departments: any) => {
        if (departments) {
          $filter.SET_SELECTED_CODES(departments);
        }
      },
      selected: toJS($filter.selectedCodes),
    });
  }

  const isAll =
    $filter.selectedList.length === 0 || $filter.selectedList.length === $filter.departments.length;

  useEffect(() => {
    $filter.SET_FILTER_INITIALIZED(true);
  }, []);

  return (
    <div className={styles.main}>
      <IconButton align="top-left" label="Фильтр по департаменту" onClick={handleOpenModal}>
        <Add />
      </IconButton>
      {isAll ? (
        <Tag className={styles.tag}> Все подразделения</Tag>
      ) : (
        $filter.selectedList.map((x) => (
          <Tag
            key={x.code}
            className={styles.tag}
            type="outline"
            filter={!!x.code}
            onClose={() => {
              $filter.removeDepartment(x.code);
            }}
          >
            {x.name}
          </Tag>
        ))
      )}
    </div>
  );
};

export default observer(DepartmentSelect);
