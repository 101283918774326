import AuthService from './AuthService';
import CatalogService from './CatalogService';
import { AppConfig } from './config';
import DashboardService from './DashboardService';
import DevPlanService from './DevPlanService';
import HumanTaskService from './HumanTaskService';
import NotificationsService from './NotificationsService';
import HcmUserManager from './oidc/HcmUserManager';
import PermissionsService from './PermissionsService';
import PersonService from './PersonService';
import TokenService from './tokenService';

export let authService: AuthService;
export let catalogService: CatalogService;
export let dashboardService: DashboardService;
export let humanTaskService: HumanTaskService;
export let personService: PersonService;
export let devPlanService: DevPlanService;
export let notificationsService: NotificationsService;
export let permissionsService: PermissionsService;
export let tokenService: TokenService;
export let hcmUserManager: HcmUserManager;

export const initInstances = (config: AppConfig) => {
  authService = new AuthService(config);
  catalogService = new CatalogService(config);

  dashboardService = new DashboardService(config);
  humanTaskService = new HumanTaskService(config);
  personService = new PersonService(config);
  devPlanService = new DevPlanService(config);
  notificationsService = new NotificationsService(config);
  permissionsService = new PermissionsService(config);
  tokenService = new TokenService(config);
  hcmUserManager = new HcmUserManager(config);
};
