export const UIColorsObject = {
  blue: '#2170FA',
  yellow: '#FFCD1C',
  orange: '#FF5703',
  green: '#59E500',
  pink: '#FF91E3',
  purple: '#9638D1',
  darkPurple: '#420F7A',
  black: '#000000',
};

export const UIColors = Object.values(UIColorsObject);

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};
