import { NavigateOptions, useSearchParams } from 'react-router-dom';

type TQueryParams = Record<string, string | number | null | undefined>

export function useRouterSearchParams(navigateOpts?: NavigateOptions) {
  const [searchParams, setSearchParams] = useSearchParams();

  function prepareValue(value: any) {
    if (typeof value === 'string') {
      const num = Number(value);

      if (!Number.isNaN(num)) {
        return num;
      }
    }

    return value;
  }

  const getAllQueryParams = () => {
    const queryParams: TQueryParams = {};

    for (const [key, value] of searchParams.entries()) {
      queryParams[key] = prepareValue(value);
    }

    return queryParams;
  };

  const getQueryParam = (key: string) => {
    const param = searchParams.getAll(key);
    const p2 = searchParams.get(key);

    switch (param.length) {
      case 0:
        return null;
      case 1:
        return prepareValue(param[0]);
      default:
        return param.map((v) => prepareValue(v));
    }
  };

  const setQueryParam = (
    params: string | TQueryParams,
    value?: string | number | null | string[],
    options?: NavigateOptions,
  ) => {
    const updatedParams = new URLSearchParams(searchParams);

    if (typeof params === 'object') {
      Object.entries(params).forEach(([key, val]) => {
        if (val == null) {
          updatedParams.delete(key);
        } else {
          if (typeof val === 'number') {
            val = val.toString();
          }

          updatedParams.set(key, val);
        }
      });
    } else if (typeof params === 'string') {
      if (value == null) {
        updatedParams.delete(params);
      } else {
        if (typeof value === 'number') {
          value = value.toString();
        } else if (_.isArray(value)) {
          updatedParams.delete(params);
          value.forEach((v) => {
            updatedParams.append(params, v);
          });
        } else {
          updatedParams.set(params, value);
        }
      }
    }

    setSearchParams(updatedParams, { ...navigateOpts, ...options });
  };

  const removeQueryParam = (params: string | string[], options?: NavigateOptions) => {
    const updatedParams = new URLSearchParams(searchParams);

    if (typeof params === 'string') {
      updatedParams.delete(params);
    } else if (Array.isArray(params)) {
      params.forEach((key) => updatedParams.delete(key));
    }

    setSearchParams(updatedParams, { ...navigateOpts, ...options });
  };

  const clearQueryParams = (options?: NavigateOptions) =>
    setSearchParams(new URLSearchParams(), { ...navigateOpts, ...options });

  return {
    getAll: getAllQueryParams,
    get: getQueryParam,
    set: setQueryParam,
    remove: removeQueryParam,
    clear: clearQueryParams,
  };
}
