


import { $user } from '@library/providers/StoreProvider';
import PageTabs, { PageTabItem } from '@library/ui/pageTabs/PageTabs';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import { useDepartmentFilter } from '@src/library/utils/hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet } from 'react-router';

const routesConfig: PageTabItem[] = [
  {
    label: 'Сотрудники',
    route: 'subordinates',
  },
  {
    label: 'Health Check',
    route: 'healthchecks',
    condition: () => !$user.isHrDev,
  },
  {
    label: 'Задачи',
    route: 'tasks',
  },
];

export function getAvailableDepartmentTabs() {
  return routesConfig.filter((x) => !x.condition || x.condition());
}

const DepartmentsPage = () => {
  const isLoading = useDepartmentFilter();

  return (
    <>
      <PageTabs tabs={getAvailableDepartmentTabs()} />
      <WithLoaderWrapper isLoading={isLoading}>
        <Outlet />
      </WithLoaderWrapper>
    </>
  );
};

export default observer(DepartmentsPage);
