import { AppConfig } from '@services/config';

import { apiRequest } from './api';

//локальный сервис аутентификации

class AuthService {
  baseUrl: string

  constructor(config: AppConfig) {
    this.baseUrl = config.authServiceUrl;
  }

  async login(username: string, password: string) {
    return apiRequest({
      url: this.baseUrl + '/account/login',
      method: 'POST',
      withAuth: false,
      data: { User: username, Password: password },
    });
  }

  async refreshToken(refreshToken: string) {
    return apiRequest({
      url: this.baseUrl + '/account/refresh',
      method: 'POST',
      withAuth: false,
      data: { refreshToken: this.refreshToken },
    });
  }
}

export default AuthService;
