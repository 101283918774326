import { Select, SelectItem } from '@carbon/react';
import { $catalog, $user } from '@library/providers/StoreProvider';
import {
  CAUSES_AVAILABLE_FOR_TASK_REVIEWERS,
  COMPETENCE_CAUSE,
  HEALTHCHECK_CAUSE,
  ONBOARDING_CAUSE,
  PERFORMANCE_CAUSE,
} from '@src/library/utils/constants';
import { OBJECT_IS_DEPARTMENT, OBJECT_IS_PERSON } from '@src/modules/task/ui/TaskForm';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { UseControllerProps, useController, useWatch } from 'react-hook-form';



interface IProps extends UseControllerProps {
  labelText: ReactNode
  objectType: string
  readOnly: boolean
}

const TaskCauseDropdown = (props: IProps) => {
  const [departmentSelected, setDepartmentSelected] = useState(true);

  const {
    field,
    fieldState: { invalid, error },
    formState: { defaultValues },
  } = useController(props);

  const values = useWatch({
    control: props.control,
  });
  useEffect(() => {
    if (props.objectType === OBJECT_IS_DEPARTMENT) {
      setDepartmentSelected(true);
    } else if (props.objectType === OBJECT_IS_PERSON) {
      setDepartmentSelected(false);
    }
  }, [props.objectType]);

  const taskCauseTypeList = useMemo(() => {
    const types = _.orderBy($catalog.taskCauseType, 'value', 'asc');
    let result = types;

    if (!($user.hasPerm('PERM_TASK_HC') && $user.hasPerm('PERM_TASK_REVIEW'))) {
      if ($user.hasPerm('PERM_TASK_HC')) {
        result = types.map((x: any) => ({ ...x, disabled: x.value !== HEALTHCHECK_CAUSE }));
      }

      if ($user.hasPerm('PERM_TASK_REVIEW')) {
        result = types.map((x: any) => ({
          ...x,
          disabled: CAUSES_AVAILABLE_FOR_TASK_REVIEWERS.indexOf(x.value) === -1,
        }));
      }
    }

    // если выбрано подразделение оценка компетенций и оценка результативности не доступны
    result = result.map((x: any) => ({
      ...x,
      disabled:
        x.disabled ||
        ((x.value === PERFORMANCE_CAUSE ||
          x.value === COMPETENCE_CAUSE ||
          x.value === ONBOARDING_CAUSE) &&
          departmentSelected),
    }));

    return result;
  }, [$catalog.taskCauseType, departmentSelected]);

  function onChange(e: any) {
    const causeType = e.target.value;
    const v = {
      causeType,
      causeText: '',
      causeObjectLink: undefined,
      causeObjectId: undefined,
    };
    field.onChange(v);
  }

  return (
    <Select
      id={field.name}
      labelText={props.labelText}
      readOnly={props.readOnly}
      name={field.name}
      onChange={onChange}
      onBlur={field.onBlur}
      value={field.value.causeType}
      ref={field.ref}
    >
      {taskCauseTypeList.map((x: any) => (
        <SelectItem key={x.value} text={x.shortName} value={x.value} disabled={x.disabled} />
      ))}
    </Select>
  );
};

export default TaskCauseDropdown;
