
import { Button } from '@carbon/react';
import { $loader } from '@library/providers/StoreProvider';
import { humanTaskService } from '@services';
import { Task } from '@services/models/task';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';

import styles from '../TaskFormModal.module.scss';

interface Props {
  task?: Task
  refetchTask?: () => void
}

const ChangeTaskStatus: FC<Props> = ({ task, refetchTask }) => {
  const loaderName = 'edit-task-status';

  const btnType = useMemo(() => {
    switch (task?.status) {
      case 'todo':
        return {
          text: 'Перевести в работу',
          newStatus: 'inprogress',
        };

      case 'inprogress':
        return {
          text: 'Перевести в готово',
          newStatus: 'done',
        };

      case 'done':
        return {
          text: 'Вернуть в работу',
          newStatus: 'todo',
        };

      default:
        break;
    }
  }, [task?.status]);

  const hahdleChange = $loader.registerHandler(loaderName, async () => {
    if (task) {
      const response = await humanTaskService.updateTaskStatus({
        id: task?.id as string,
        code: btnType?.newStatus as string,
      });

      if (response.isSuccess && refetchTask) {
        refetchTask();
      }
    }
  });

  return (
    <Button size="sm" onClick={hahdleChange} className={styles.changeTaskStatusButton}>
      {btnType?.text}
    </Button>
  );
};

export default observer(ChangeTaskStatus);
