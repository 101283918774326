
import { Column, FormLabel, Row, Stack } from '@carbon/react';
import { HealthCheckIndex } from '@services/models/health-check';
import React, { FC } from 'react';

import { leftIndexEnumColumn, rightIndexEnumColumn } from '../model/helpers/HealthCheckIndexesMeta';

import styles from './HealthCheckIndexes.module.scss';
import HealthCheckIndexField from './HealthCheckIndexField';

interface HealthCheckIndexesProps {
  value: HealthCheckIndex[]
  setValue: Function
  readOnly: boolean
  errors: any
}

const HealthCheckIndexes: FC<HealthCheckIndexesProps> = ({
  value = [],
  setValue: setFormValue,
  readOnly,
  errors,
}) => {
  function setValue(id: string, v: number) {
    const updated = value.filter((x: HealthCheckIndex) => x.code !== id);

    updated.push({
      //@ts-ignore
      code: id,
      value: v,
    });
    setFormValue('indexList', updated);
  }

  const values = value.reduce((obj: any, idx: HealthCheckIndex) => {
    obj[idx.code!] = idx.value === 0 ? '' : idx.value;

    return obj;
  }, {});

  return (
    <Stack gap={6}>
      <Row>
        {errors.message ? <FormLabel className={styles.error}> {errors.message}</FormLabel> : <></>}
      </Row>

      <Row>
        <Column>
          <Stack gap={6}>
            {leftIndexEnumColumn.map((x: any) => {
              return (
                <HealthCheckIndexField
                  id={x.key}
                  key={x.key}
                  label={x.title}
                  readOnly={readOnly}
                  setValue={setValue}
                  error={errors[x.key]}
                  defaultValue={values[x.key]}
                />
              );
            })}
          </Stack>
        </Column>
        <Column>
          <Stack gap={6}>
            {rightIndexEnumColumn.map((x: any) => (
              <HealthCheckIndexField
                id={x.key}
                key={x.key}
                label={x.title}
                readOnly={readOnly}
                setValue={setValue}
                error={errors[x.key]}
                defaultValue={values[x.key]}
              />
            ))}
          </Stack>
        </Column>
      </Row>
    </Stack>
  );
};

export default HealthCheckIndexes;
