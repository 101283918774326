import { computed, createAtom, makeObservable, reaction } from 'mobx';
import { QueryClient, QueryKey, QueryObserver, QueryObserverOptions } from 'react-query';


export class MobxQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> {
  private queryObserver = new QueryObserver(this.queryClient, this.defaultedQueryOptions)

  private atom = createAtom(
    'MobxQuery',
    () => this.startTracking(),
    () => this.stopTracking(),
  )

  constructor(
    private getOptions: () => QueryObserverOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryData,
      TQueryKey
    >,
    private queryClient: QueryClient,
  ) {
    makeObservable(this, {
      data: computed,
    });
  }

  get result() {
    this.atom.reportObserved();
    this.queryObserver.setOptions(this.defaultedQueryOptions);

    return this.queryObserver.getOptimisticResult(this.defaultedQueryOptions);
  }

  get data() {
    const data = this.result.data;

    if (!data) {
      throw this.queryObserver.fetchOptimistic(this.defaultedQueryOptions);
    }

    return data;
  }

  private unsubscribe = () => {}

  private startTracking() {
    const unsubscribeReaction = reaction(
      () => this.defaultedQueryOptions,
      () => {
        this.queryObserver.setOptions(this.defaultedQueryOptions);
      },
    );

    const unsubscribeObserver = this.queryObserver.subscribe(() => {
      this.atom.reportChanged();
    });

    this.unsubscribe = () => {
      unsubscribeObserver();
      unsubscribeReaction();
    };
  }

  private stopTracking() {
    this.unsubscribe();
  }

  private get defaultedQueryOptions() {
    return this.queryClient.defaultQueryOptions(this.getOptions());
  }
}
