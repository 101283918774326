import { $modal, $user } from '@library/providers/StoreProvider';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, useEffect } from 'react';



import ChooseRoleModal from './ui/ChooseRoleModal';

type IProps = {
  children: ReactNode
}

const RoleProvider: FC<IProps> = ({ children }) => {
  const shouldSelectRole = $user.availableRoles.length > 1 && !$user.roleGroup;

  useEffect(() => {
    shouldSelectRole &&
      $modal.add(ChooseRoleModal, { roles: $user.availableRoles, mandatorySelection: true });
  }, [shouldSelectRole]);

  return shouldSelectRole ? <></> : <>{children}</>;
};

export default observer(RoleProvider);
