import React, { type FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  emptyPlaceHolderPlugin,
  labelFormatter,
  prcntTicks,
  prcntTooltip,
} from '../../model/helpers/formatters';
import { DepartmentStats } from '../../model/types/pep.types';

interface PepPcntChartProps {
  departmentStats: DepartmentStats[]
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '% Сотрудников с ИПР',
    },
    tooltip: prcntTooltip,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: prcntTicks,
    },
  },
};

const PepPcntChart: FC<PepPcntChartProps> = ({ departmentStats }) => {
  const data = useMemo(() => {
    const labels = departmentStats.map(labelFormatter);
    const withPep = departmentStats.map((x) => (100 * x.pepCount) / x.personCount);
    const withoutPep = departmentStats.map(
      (x) => (100 * (x.personCount - x.pepCount)) / x.personCount,
    );

    return {
      labels,
      datasets: [
        {
          label: 'С ИПР',
          data: withPep,
          backgroundColor: '#59E500',
          barThickness: 40,
        },
        {
          label: 'Без ИПР',
          data: withoutPep,
          backgroundColor: '#FF5703',
          barThickness: 40,
        },
      ],
    };
  }, [departmentStats]);

  return <Bar options={options} data={data} plugins={[...emptyPlaceHolderPlugin]} />;
};

export default PepPcntChart;
