
import { Button, ButtonSet, TextArea } from '@carbon/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { $loader } from '@library/providers/StoreProvider';
import { formErrors } from '@library/utils/constants';
import MentionsTextarea from '@src/library/ui/mentions-textarea/MentionsTextarea';
import { PersonCardForList } from '@src/services/models/person';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface AddCommentFormProps {
  formId: string
  onCommentAdd?: Function
  disabled?: boolean
  persons?: PersonCardForList[]
}

const schema = z.object({
  comment: z.string().min(1, { message: formErrors.required }),
});

const AddCommentForm: FC<AddCommentFormProps> = ({ formId, onCommentAdd, disabled, persons }) => {
  const [inFocus, setInFocus] = useState(false);
  const [mentions, setMentions] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: '',
    },
    resolver: zodResolver(schema),
  });
  const values = watch();

  async function onSubmit(data: any) {
    onCommentAdd &&
      (await onCommentAdd({
        comment: values.comment,
        mentionedList: mentions,
      }));
    setValue('comment', '');
    setInFocus(false);
  }

  const onCancel = () => {
    setValue('comment', '');
    setInFocus(false);
  };

  return (
    <>
      {!disabled ? (
        <form id={formId} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
          {persons ? (
            <MentionsTextarea
              id="comment"
              value={values?.comment}
              onChange={(value) => setValue('comment', value)}
              onFocus={() => setInFocus(true)}
              placeholder="Добавить комментарий..."
              persons={persons}
              mentions={mentions}
              setMentions={setMentions}
              invalid={!!errors.comment}
              invalidText={errors.comment?.message}
            />
          ) : (
            <TextArea
              labelText=""
              placeholder="Добавить комментарий..."
              rows={inFocus ? 3 : 1}
              id="comment"
              {...register('comment')}
              invalid={!!errors.comment}
              invalidText={<>{errors.comment?.message}</>}
              onFocus={() => setInFocus(true)}
            />
          )}

          {inFocus && (
            <ButtonSet className="mt-10">
              <Button kind="primary" type="submit" disabled={$loader.isRunHandler(formId)}>
                {$loader.isRunHandler(formId) ? 'Загрузка...' : 'Отправить'}
              </Button>
              <Button kind="ghost" onClick={onCancel}>
                Отмена
              </Button>
            </ButtonSet>
          )}
        </form>
      ) : (
        <></>
      )}
    </>
  );
};

export default observer(AddCommentForm);
