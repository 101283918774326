
import {
  Checkbox,
  /* @ts-ignore */
  MultiSelect,
} from '@carbon/react';
import { getQuarters } from '@helpers/date'
import { $catalog, $filter } from '@library/providers/StoreProvider'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'
import { dashboardService } from '@services'
import { HCAnalytics } from '@services/models/dashboard/hcanalytics'
import { HCFilter } from '@services/models/dashboard/hcfilter'
import { useLoader } from '@src/library/utils/hooks';
import { HCHeatMap, HCReasonsChart, HCTaskMetricsChart } from '@src/modules/analytic';
import HCAnalyticTable from '@src/modules/analytic/ui/health-checks/HCAnalyticTable'
import { ICatalogHealthCheckReason } from '@src/modules/catalog/model/catalogStore'
import DepartmentSelect from '@src/modules/department/ui/DepartmentSelect'
import classNames from 'classnames';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo, useState } from 'react';

import styles from './HCAnalyticsPage.module.scss';

const START_DATE = '2023-10-01';

const HCAnalyticsPage: FC = () => {
  const startDate = dayjs(START_DATE);
  const currentDate = dayjs();
  const [analytics, setAnalytics] = useState<HCAnalytics | null>(null);
  const quarters = getQuarters(startDate, currentDate);
  const selectedQuarters = $filter.HCAnalyticsPeriods;
  const reasonsOptions = toJS($catalog.healthCheckReason);
  const [reasons, setReasons] = useState<ICatalogHealthCheckReason[]>($catalog.healthCheckReason);
  const [isHierarchy, setIsHierarchy] = useState<boolean>(true);

  const filters = useMemo(() => {
    return {
      departments: $filter.selectedCodes,
      departmentHierarchy: isHierarchy,
      periods: selectedQuarters?.map((item) => ({
        startDate: item?.value?.startDate,
        endDate: item?.value?.endDate,
      })),
      reasons: reasons?.map((item) => item.value),
    } as HCFilter;
  }, [selectedQuarters, isHierarchy, reasons, $filter.selectedCodes]);

  const isLoading = useLoader(async () => {
    if (selectedQuarters?.length) {
      const response = await dashboardService.getHCAnalytics(filters);

      if (response.isSuccess && response.data) {
        setAnalytics(response?.data[0]);
      }
    }
  }, [filters]);

  const handleChahgePeriod = useCallback((e: any) => {
    $filter.SET_SELECTED_HC_PERIODS(e.selectedItems);
  }, []);

  return (
    <>
      <div className={classNames(styles.filters)}>
        <div className={styles.filtersItem}>
          <DepartmentSelect />
        </div>
        <div className={classNames(styles.filtersItem, styles.hierarchyCheckbox)}>
          <Checkbox
            id="check-hierarchy"
            labelText="Учитывать иерархию подразделений"
            checked={isHierarchy}
            onChange={() => setIsHierarchy(!isHierarchy)}
          />
        </div>
      </div>

      <div className={classNames(styles.filters, 'mt-20')}>
        <MultiSelect
          id="quarters"
          label={
            selectedQuarters?.length ? selectedQuarters.map((item) => item?.label).join(', ') : ''
          }
          onChange={handleChahgePeriod}
          initialSelectedItems={selectedQuarters}
          items={quarters}
          itemToString={(option: any) => option.label}
          compareItems={(option: any) => option.id}
          size="lg"
          className={classNames(
            styles.filtersItem,
            'multiselect-filter--without-tag',
            styles.filterableSelect,
          )}
          titleText="Период"
        />
        <MultiSelect
          id="reason"
          label={
            !reasons?.length || reasons?.length === $catalog.healthCheckReason.length
              ? 'Все'
              : reasons?.map((item: any) => item?.shortName).join(', ')
          }
          onChange={(e: any) => setReasons(e.selectedItems)}
          initialSelectedItems={reasons}
          items={reasonsOptions}
          itemToString={(option: any) => option.shortName}
          size="lg"
          className={classNames(
            styles.filtersItem,
            'multiselect-filter--without-tag',
            styles.filterableSelect,
          )}
          titleText="Причина проведения"
        />
      </div>

      <WithLoaderWrapper isLoading={isLoading}>
        <HCHeatMap filters={filters} className={'mt-30'} />
        {selectedQuarters?.length > 0 && (
          <div className={classNames(styles.charts, 'mt-30')}>
            <div className={styles.chartsItem}>
              {!!analytics?.reasonMetrics && (
                <HCReasonsChart
                  data={analytics?.reasonMetrics}
                  title={'Причины проведения'}
                  className={styles.chartsItemChart}
                />
              )}
            </div>
            <div className={styles.chartsItem}>
              {!!analytics?.taskMetrics && (
                <HCTaskMetricsChart
                  data={analytics?.taskMetrics}
                  title={'Связанные задачи'}
                  className={styles.chartsItemChart}
                />
              )}
            </div>
          </div>
        )}
        <HCAnalyticTable filters={filters} className={classNames(styles.table, 'mt-30')} />
      </WithLoaderWrapper>
    </>
  );
};

export default observer(HCAnalyticsPage);
