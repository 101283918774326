import { apiRequest } from '@services/api';
import { AppConfig } from '@services/config';
import { HRPPList } from '@services/models/catalog/hrpplist';

export interface IBodyCustomDepart {
  customManager: string
  code: string
}

class CatalogService {
  baseUrl: string

  constructor(config: AppConfig) {
    this.baseUrl = config.catalogServiceUrl;
  }

  async fetch() {
    return apiRequest({
      url: this.baseUrl + '/catalog_rows',
      method: 'GET',
      withAuth: false,
      cacheDuration: 15 * 60000,
    });
  }

  async fetchDepartments(manager: string | undefined, customManager?: string | undefined) {
    return apiRequest({
      url: this.baseUrl + '/departments',
      data: {
        manager,
        customManager,
      },
      method: 'GET',
      cacheDuration: 15 * 60000,
    });
  }

  async getCustomDepartments(customManager: string) {
    return apiRequest({
      url: this.baseUrl + '/custom_departments',
      data: {
        customManager,
      },
      method: 'GET',
    });
  }

  async addCustomDepartments(data: IBodyCustomDepart[]) {
    return apiRequest({
      url: this.baseUrl + '/custom_departments',
      data: { data },
      method: 'POST',
    });
  }

  async deleteCustomDepartments(id: string) {
    return apiRequest({
      url: this.baseUrl + `/custom_department/${id}`,
      method: 'DELETE',
    });
  }

  async getHRPPList() {
    return apiRequest<HRPPList>({
      url: this.baseUrl + `/departments_hrpps`,
      method: 'GET',
      cacheDuration: 15 * 60000,
    });
  }
}

export default CatalogService;
