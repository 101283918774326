
import { ChevronDown, IbmWatsonxAssistant } from '@carbon/icons-react';
import { localStore } from '@helpers/other';
import { $modal } from '@library/providers/StoreProvider';
import { $windowSize } from '@library/utils/windowSize';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import FeedbackFormModal from './FeedbackFormModal';
import styles from './UserFeedbackViewer.module.scss';

const UserFeedbackViewer = () => {
  const [visible, setVisible] = useState(false);
  const [closed, setClosed] = useState(localStore.get('feedback_button_closed', false));

  useEffect(() => {
    setTimeout(() => setVisible(true), 5000);
  }, []);

  function handleOpenFeedbackModal() {
    if (closed) {
      handleClose();
    } else {
      $modal.add(FeedbackFormModal);
    }
  }

  function handleClose() {
    setClosed(!closed);
    localStore.set('feedback_button_closed', !closed);
  }

  return (
    <div
      className={classNames(
        styles.wrapper,
        visible && styles.wrapperVisible,
        visible && closed && styles.wrapperClosed,
        $windowSize.isMobileSize && styles.wrapperMobile,
      )}
    >
      <div className={styles.icon} onClick={handleOpenFeedbackModal}>
        <IbmWatsonxAssistant />
      </div>
      <div className={styles.close} onClick={handleClose}>
        <ChevronDown />
      </div>
    </div>
  );
};

export default observer(UserFeedbackViewer);
