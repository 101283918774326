import React, { FC } from 'react';

import styles from './TablePlaceholder.module.scss';

type IProps = {
  text: string
}

const TablePlaceholder: FC<IProps> = ({ text }) => {
  return <h2 className={styles.placeholder}>{text}</h2>;
};

export default TablePlaceholder;
