
import { $user } from '@library/providers/StoreProvider';
import { personService } from '@services';
import { useLoader } from '@src/library/utils/hooks';
import { FilterItem } from '@src/modules/filters/model/hooks/usePersonsFilter';
import { useState } from 'react';

export const useAuthorFilter = () => {
  const [persons, setPersons] = useState<any[]>([]);
  const [author, setAuthor] = useState<FilterItem | ''>('');

  const isAuthorsLoading = useLoader(async () => {
    const manager = $user.hasPerm('PERM_TASK_MANAGER') ? $user.nickname : undefined;

    const response = await personService.fetch(manager, undefined, manager);

    if (response.isSuccess && response.data?.data) {
      const data = _.orderBy(response.data?.data, 'nickName', 'asc');
      const mapped = data?.map((user, index) => ({
        key: [index, user.nickName].join('_'),
        text: user.nickName,
        value: user.nickName,
      }));
      setPersons(mapped);
    }
  }, []);

  return {
    persons,
    isAuthorsLoading,
    author,
    setAuthor,
  };
};
