
import { Select, SelectItem } from '@carbon/react';
import { formatPeriod } from '@helpers/date';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import { personService } from '@services';
import { Onboarding } from '@services/models/person';
import { ONBOARDING_CAUSE } from '@src/library/utils/constants';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { UseControllerProps, useController, useWatch } from 'react-hook-form';

interface IProps extends UseControllerProps {
  labelText: ReactNode
  readOnly: boolean
}

const OnboardingDropdown = (props: IProps) => {
  const [onboardings, setOnboardings] = useState<Onboarding[]>([]);
  const [loaded, setLoaded] = useState(false);

  const {
    field,
    fieldState: { invalid, error },
    formState: { defaultValues },
  } = useController(props);

  const values = useWatch({
    control: props.control,
  });

  async function loadOnboardings(person: string) {
    let resultList: Onboarding[] = [];
    setLoaded(false);
    const response = await personService.fetchOnboardings(person);

    if (response.isSuccess && response.data?.data) {
      const fieldValue = parseInt(field.value.causeObjectId);
      resultList = response.data?.data;
      const found = resultList.find((x) => x.id === fieldValue);

      if (resultList.length && !found) {
        field.onChange(getValue(resultList, resultList[0].id! + ''));
      }

      setOnboardings(_.orderBy(resultList, 'startDate', 'desc'));
    }

    setLoaded(true);
  }

  useEffect(() => {
    if (values.personObject.nickName && values.taskCause.causeType === ONBOARDING_CAUSE) {
      loadOnboardings(values.personObject.nickName);
    }
  }, [values.taskCause.causeType]);

  function getTitle(x: Onboarding) {
    return `${formatPeriod(x?.startDate, x?.endDate)}`;
  }

  function getValue(list: Onboarding[], token: string) {
    const id = parseInt(token);
    const onBoarding = list.find((x) => x.id === id);

    return {
      causeObjectId: token,
      causeText: getTitle(onBoarding!),
      causeType: ONBOARDING_CAUSE,
    };
  }

  const onChange = (e: any) => {
    const token = e.target.value;
    field.onChange(getValue(onboardings, token));
  };

  const options = useMemo(() => {
    if (onboardings?.length > 0) {
      return onboardings.map((sr) => ({ text: getTitle(sr), value: sr.id }));
    } else {
      setLoaded(true);

      return [{ text: 'нет адаптаций по сотруднику', value: '', disabled: true }];
    }
  }, [onboardings]);

  return (
    <WithLoaderWrapper isLoading={!loaded} size="small">
      <Select
        id={field.name}
        labelText={props.labelText}
        invalid={invalid}
        invalidText={error?.root?.message}
        readOnly={props.readOnly}
        name={field.name}
        onChange={onChange}
        onBlur={field.onBlur}
        value={field.value.causeObjectId}
        ref={field.ref}
      >
        {options.map((x: any) => (
          <SelectItem key={x.value} text={x.text} value={x.value} disabled={x.disabled ?? false} />
        ))}
      </Select>
    </WithLoaderWrapper>
  );
};

export default OnboardingDropdown;
