
import { Add, WarningFilled } from '@carbon/icons-react';
import { IconButton, Tag } from '@carbon/react';
import { $filter, $modal } from '@library/providers/StoreProvider';
import { Department } from '@services/models/catalog';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';

import DepartmentsModal from '../../../department/ui/DepartmentsModal';

import styles from './DepartmentSelect.module.scss';

type Props = {
  id?: string
  departmentCode?: string
  departmentList: Department[]
  onChange: (value: string) => void
  readOnly?: boolean
  invalid?: boolean
  invalidText?: string
}

const SingleDepartmentSelect = ({
  id,
  departmentCode,
  departmentList,
  onChange,
  readOnly,
  invalid,
  invalidText,
}: Props) => {
  useEffect(() => {
    if (departmentList) {
      $filter.SET_DEPARTMENTS(departmentList);
    }
  }, [departmentList]);

  const handleOpenModal = () => {
    $modal.add(DepartmentsModal, {
      onSelect: (departments: any) => {
        if (departments) {
          onChange(departments[0]);
        }
      },
      selected: [departmentCode],
      singleValue: true,
    });
  };

  const depsName = useMemo(() => {
    return departmentList?.find((item: any) => item?.code === departmentCode)?.name;
  }, [departmentList, departmentCode]);

  return (
    <div>
      <div className={styles.main} id={id}>
        {!readOnly && (
          <IconButton
            align="top-left"
            label="Фильтр по департаменту"
            onClick={handleOpenModal}
            size="sm"
          >
            <Add />
          </IconButton>
        )}
        {!departmentCode ? (
          <Tag className={styles.tag}>Выберите подразделение</Tag>
        ) : (
          <Tag key={departmentCode} className={styles.tag} type="outline">
            {depsName}
          </Tag>
        )}
        {invalid && <WarningFilled className={styles.error} />}
      </div>
      {invalid && (
        <div
          role="alert"
          className={`cds--form-requirement ${styles.error}`}
          id={`${id}-error-msg`}
          dir="auto"
        >
          {invalidText}
        </div>
      )}
    </div>
  );
};

export default observer(SingleDepartmentSelect);
