import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';


import { apiRequest } from '@services/api';
import { AppConfig } from '@services/config'
import { HCFilter } from '@services/models/dashboard'
import { FileItem, IPRFilter, JobProfilesList } from '@services/models/health-check'
import { indexIds } from '@src/modules/healthcheck';

import { TasksData } from './models/person';
  
class DevPlanService {  
  baseUrl: string

  constructor(config: AppConfig) {
    this.baseUrl = config.devPlanServiceUrl;
  }

  toApiPayload(inputs: any, files?: FileItem[]) {
    const { id, author, department, reason, startDate, link, indexList } = inputs;

    const mappedIndexList = indexList.map((x: any) => ({
      code: indexIds[x.code],
      value: x.value ?? 0,
    }));

    let filesList = {};

    if (files && files?.length > 0) {
      filesList = { data: files };
    }

    const payload = {
      id: id ?? 'id',
      author: author!,
      department: department!,
      reason: reason!,
      startDate: startDate && dayjs(startDate).startOf('quarter').format('YYYY-MM-DD'),
      endDate: startDate && dayjs(startDate).endOf('quarter').format('YYYY-MM-DD'),
      link: {
        linkText: '',
        linkDescription: '',
        ...link,
        id: 'id',
      },

      indexList: mappedIndexList,
      historyList: [],
      commentsList: [],
      filesList,
    };

    return payload;
  }

  async get(id: string) {
    return apiRequest({
      url: this.baseUrl + '/health_check/' + id,
      method: 'GET',
    });
  }

  async fetch(params: {
    startDate?: string
    endDate?: string
    manager?: string
    departments?: string[]
    periodStartDate?: string
    periodEndDate?: string
    offset?: number
    limit?: number
    sortBy?: string
    sortOrder?: string
  }) {
    return apiRequest({
      url: this.baseUrl + '/health_checks',
      data: params,
      method: 'GET',
    });
  }

  async createHealthCheck(currentUser: string, hc: any) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + '/health_check?currentUser=' + currentUser,
      data: hc,
    });
  }

  async updateHealthCheck(currentUser: string, hc: any) {
    return apiRequest({
      method: 'PUT',
      url: this.baseUrl + `/health_check/${hc.id}?currentUser=${currentUser}`,
      data: hc,
    });
  }

  async deleteHealthCheck(id: string) {
    return apiRequest({
      method: 'DELETE',
      url: this.baseUrl + `/health_check/${id}`,
    });
  }

  async doBatchHealthCheckDelete(ids: string[]) {
    return axios.all(ids.map((id) => this.deleteHealthCheck(id)));
  }

  async fetchPlanPerformance(params: {
    personNickName: string
    startDate?: string
    offset?: number
    limit?: number
  }) {
    return apiRequest({
      url: this.baseUrl + '/plans_performance',
      data: params,
      method: 'GET',
    });
  }

  /*
  API не реализовано по состоянию на 17.03.2024
  */
  async getHcComments(params: { id: string }) {
    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/health_check/${params.id}/comments`,
      data: params,
    });
  }

  async addHcComment(params: { id: string; commentText: string }) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + `/health_check/${params.id}/comments`,
      data: params,
    });
  }

  async fetchSkillReview(params: { person: string; startDate?: string }) {
    const data = {
      personNickName: params.person,
      startDate: params.startDate,
    };

    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/skill_review`,
      data,
    });
  }

  async fetchAllPersonalEvolutionPlans(
    data: IPRFilter,
    limit: number,
    offset: number,
    sortBy?: string,
    sortOrder?: string,
  ) {
    const defaultUrl = `/iprs?offset=${offset}&limit=${limit}`;
    const sort = !!sortBy && !!sortOrder && `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    const url = sort ? defaultUrl + sort : defaultUrl;

    return apiRequest({
      method: 'POST',
      url: this.baseUrl + url,
      data,
    });
  }

  async fetchPersonalEvolutionPlans(params: { person: string }) {
    const data = {
      person: params.person,
    };

    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/ipr`,
      data,
    });
  }

  async fetchPersonalEvolutionPlan(id: string) {
    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/ipr/${id}`,
    });
  }

  async deletPersonalEvolutionPlan(id: string) {
    return apiRequest({
      method: 'DELETE',
      url: this.baseUrl + `/ipr/${id}`,
    });
  }

  async createPersonalEvolutionPlan(data: any) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + '/ipr',
      data,
    });
  }

  async updatePersonalEvolutionPlan(data: any) {
    return apiRequest({
      method: 'PUT',
      url: this.baseUrl + `/ipr/${data.id}`,
      data,
    });
  }

  async getPepComments(params: { id: string }) {
    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/ipr/${params.id}/comments`,
      data: params,
    });
  }

  async addPepComment(params: { id: string; commentText: string }) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + `/ipr/${params.id}/comments`,
      data: params,
    });
  }

  async getFilesHC(id: string) {
    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/health_check/${id}/files`,
    });
  }

  async addFileHC(params: { id: string; body: FormData }) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + `/health_check/${params?.id}/files`,
      data: params?.body,
    });
  }

  async removeFileHC(id: string, fileId: string) {
    return apiRequest({
      method: 'DELETE',
      url: this.baseUrl + `/health_check/${id}/files/${fileId}`,
    });
  }

  async downloadFileHC(id: string, fileId: string) {
    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/health_check/${id}/files/${fileId}`,
    });
  }

  async getHCFiltred(
    filters: HCFilter,
    limit: number,
    offset: number,
    sortBy?: 'code' | 'departmentName' | 'startDate' | 'createDate' | 'author' | 'reason',
    sortOrder?: 'asc' | 'desc',
  ) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + '/health_checks_filtered',
      data: filters,
      params: {
        offset,
        limit,
        sortBy,
        sortOrder,
      },
    });
  }

  async getJobProfiles() {
    return apiRequest<JobProfilesList>({
      method: 'GET',
       url: this.baseUrl + `/job_profiles`,
    });
  }

  async getAidayTaskCount(login: string) {
    return apiRequest<TasksData>({
      method: 'GET',
      url: this.baseUrl + `/aiday_tasks/${login}/count`,
    });
  }
}

export default DevPlanService;
