import { isEqualLogin } from '@helpers/text.js';
import { $catalog, $filter, $user } from '@library/providers/StoreProvider';
import { AppConfig } from '@services/config';

import { authService } from '.';

const TOKEN_KEY = 'hcm-token';
const REFRESH_TOKEN_KEY = 'refresh-hcm-token';

//в основном локальные сервисы
// TODO рассмотреть возможность сделать его store

class TokenService {
  private _token = ''
  private _refreshToken = ''
  private _tokenParsed: any = undefined
  private _authenticated = false
  private config

  constructor(config: AppConfig) {
    this.config = config;

    if (!this.config.useOidc) {
      const token = sessionStorage.getItem(TOKEN_KEY);

      if (token) {
        this._token = token;
        this.parseToken();
        this._authenticated = true;
      }

      const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);

      if (refreshToken) {
        this._refreshToken = refreshToken;
      }
    }
  }

  async clearToken() {
    //TODO
    if (!this.config.useOidc) {
      this._authenticated = false;
      this._token = '';
      this._refreshToken = '';

      this._tokenParsed = undefined;
      sessionStorage.removeItem(TOKEN_KEY);
      sessionStorage.removeItem(REFRESH_TOKEN_KEY);
      window.location.href = '/';
    }
  }

  shouldRefresh() {
    if (this.config.useOidc) {
      return false;
    } else {
      return this._tokenParsed ? this._tokenParsed.exp * 1000 < new Date().getTime() : true;
    }
  }

  async refreshToken() {
    const response = await authService.refreshToken(this._refreshToken);

    if (response.isSuccess) {
      this.setUser(response.data);
    }
  }

  async setUser(user: any) {
    if (this.config.useOidc) {
      const email: string = user!.profile.upn as string;
      const nickname = email.split('@')[0];
      user?.expires_at && console.log(`expires at ${new Date(user!.expires_at * 1000)}`);

      this._token = user!.access_token;
      this._authenticated = true;
      this._tokenParsed = {
        ...user,
        preferred_username: nickname,
        email,
      };
    } else {
      this._token = user.token;
      this._refreshToken = user.refreshToken;
      this._authenticated = true;

      sessionStorage.setItem(TOKEN_KEY, this._token);
      sessionStorage.setItem(REFRESH_TOKEN_KEY, this._refreshToken);

      this.parseToken();
    }

    await this.initStores();
  }

  async initStores() {
    const prevNickname = $user.nickname;
    $user.SET_LOGGED_IN_USER(this._tokenParsed);

    if (!isEqualLogin(prevNickname, $user.nickname)) {
      $filter.clear();
      await Promise.all([$catalog.loadCatalog(), $user.fetchPermissions(), $user.fetchPerson()]);
    }
  }

  private parseToken() {
    this._tokenParsed = JSON.parse(atob(this._token.split('.')[1]));
  }

  get token() {
    return this._token;
  }

  get authenticated() {
    return this._authenticated;
  }
}

export default TokenService;
