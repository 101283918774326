
import RolesPage from '@src/pages/admin/modules/roles/ui/RolesPage';
import AdministrationPage, { getAvailableAdminTabs } from '@src/pages/admin/ui/AdministrationPage';
import { AnalyticsPage } from '@src/pages/analytics';
import HCAnalyticsPage from '@src/pages/analytics/modules/health-checks/ui/HCAnalyticsPage';
import { OnboardingsAnalyticsPage } from '@src/pages/analytics/modules/onboardings';
import { PepAnalyticsPage } from '@src/pages/analytics/modules/pep';
import { TaskAnalyticsPage } from '@src/pages/analytics/modules/task';
import { getAvailableAnalyticTabs } from '@src/pages/analytics/ui/AnalyticsPage';
import LoginPage from '@src/pages/auth/login/ui/LoginPage';
import CabinetPage from '@src/pages/cabinet/ui/CabinetPage';
import { DepartmentsPage } from '@src/pages/departments';
import { HealthChecksPage } from '@src/pages/departments/modules/health-checks';
import SubordinatesPage from '@src/pages/departments/modules/subordinates/ui/SubordinatesPage';
import { TasksPage } from '@src/pages/departments/modules/tasks';
import { getAvailableDepartmentTabs } from '@src/pages/departments/ui/DepartmentsPage';
import { EmployeesListPage } from '@src/pages/employees/modules/persons';
import { EmployeesTasksPage } from '@src/pages/employees/modules/tasks';
import MyEmployeesPage, { getAvailableEmployeeTabs } from '@src/pages/employees/ui/MyEmployeesPage';
import ErrorPage from '@src/pages/errors/ui/ErrorPage';
import FeedbackPage from '@src/pages/feedback/ui/FeedbackPage';
import ProfileNotificationsPage from '@src/pages/profile/ui/ProfileNotificationsPage';
import ProfilePage from '@src/pages/profile/ui/ProfilePage';
import { JobProfilesPage } from '@src/pages/reference/modules/job-profiles';
import CompanyStructurePage from '@src/pages/reference/ui/CompanyStructurePage';
import React from 'react';
import { Navigate, RouteProps, createBrowserRouter } from 'react-router-dom';

import DefaultLayout from '../layouts/defaultLayout/DefaultLayout';
import SimpleLayout from '../layouts/simpleLayout/SimpleLayout';
import PermissionsProvider from '../providers/PermissionsProvider';
import PluginsProvider from '../providers/PluginsProvider';
import RoleProvider from '../providers/RoleProvider';
import SignOnProvider from '../providers/SignOnProvider';
import { $user } from '../providers/StoreProvider';

type AppRoutesProps = RouteProps & {
  authOnly?: boolean
}

export enum AppRouter {
  USER_PROFILE = 'user_profile',
  ADMINISTRATION = 'administration',
  ROLES = 'roles',
  CABINET = 'cabinet',
  DEPARTMENTS = 'departments',
  SUBORDINATES = 'subordinates',
  HEATH_CHECK = 'healthchecks',
  HEATH_CHECK_DETAIL = 'healthchecks_detail',
  TASKS = 'tasks',
  EMPLOYEES = 'employees',
  PERSONS = 'persons',
  PROFILE = 'profile',
  COMPANY_STRUCTURE = 'company_structure',
  JOB_PROFILES = 'job_profiles',
  ANALYTICS = 'analytics',
  PEPS = 'peps',
  ONBOARDINGS = 'onboardings',
  NOTIFICATIONS = 'notifications',
  FEEDBACK = 'feedback',
  LOGIN = 'login',
  NOT_FOUND = 'not_found',
}

export const RouterPath: Record<AppRouter, string> = {
  [AppRouter.USER_PROFILE]: 'user-profile',
  [AppRouter.ADMINISTRATION]: 'administration',
  [AppRouter.ROLES]: 'roles',
  [AppRouter.CABINET]: 'user-profile/:id?/:pageKey?',
  [AppRouter.DEPARTMENTS]: 'departments',
  [AppRouter.SUBORDINATES]: 'subordinates',
  [AppRouter.HEATH_CHECK]: 'healthchecks',
  [AppRouter.HEATH_CHECK_DETAIL]: 'healthchecks/q/:qDate',
  [AppRouter.TASKS]: 'tasks',
  [AppRouter.EMPLOYEES]: 'employees',
  [AppRouter.PERSONS]: 'persons',
  [AppRouter.PROFILE]: 'profile',
  [AppRouter.COMPANY_STRUCTURE]: 'company-structure',
  [AppRouter.JOB_PROFILES]: 'job-profiles',
  [AppRouter.ANALYTICS]: 'analytics',
  [AppRouter.PEPS]: 'peps',
  [AppRouter.ONBOARDINGS]: 'onboardings',
  [AppRouter.NOTIFICATIONS]: 'profile/notifications',
  [AppRouter.FEEDBACK]: 'feedback',
  [AppRouter.LOGIN]: 'login',
  [AppRouter.NOT_FOUND]: '*',
};

export const routerConfig = createBrowserRouter([
  {
    path: '/',
    element: (
      <PluginsProvider>
        <SignOnProvider>
          <RoleProvider>
            <DefaultLayout showNavigation={true} />
          </RoleProvider>
        </SignOnProvider>
      </PluginsProvider>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={RouterPath.user_profile} replace={true} />,
      },
      {
        path: RouterPath.administration,
        element: (
          <PermissionsProvider condition={() => $user.hasPerm('PERM_USER_ROLE_ADD')}>
            <AdministrationPage />
          </PermissionsProvider>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={getAvailableAdminTabs()[0]?.route || ''} replace={true} />,
          },
          {
            path: RouterPath.roles,
            element: <RolesPage />,
          },
        ],
      },
      {
        path: RouterPath.cabinet,
        element: <CabinetPage />,
      },
      {
        path: RouterPath.departments,
        element: <DepartmentsPage />,
        children: [
          {
            index: true,
            element: <Navigate to={getAvailableDepartmentTabs()[0]?.route || ''} replace={true} />,
          },
          {
            path: RouterPath.subordinates,
            element: <SubordinatesPage />,
          },
          {
            path: RouterPath.healthchecks,
            element: <HealthChecksPage />,
          },
          {
            path: RouterPath.healthchecks_detail,
            element: <HealthChecksPage />,
          },
          {
            path: RouterPath.tasks,
            element: <TasksPage />,
          },
        ],
      },
      {
        path: RouterPath.employees,
        element: <MyEmployeesPage />,
        children: [
          {
            index: true,
            element: <Navigate to={getAvailableEmployeeTabs()[0]?.route || ''} replace={true} />,
          },
          {
            path: RouterPath.employees,
            element: <EmployeesListPage />,
          },
          {
            path: RouterPath.tasks,
            element: <EmployeesTasksPage />,
          },
        ],
      },
      {
        path: RouterPath.profile,
        element: <ProfilePage />,
      },
      {
        path: RouterPath.company_structure,
        element: <CompanyStructurePage />,
      },
      {
        path: RouterPath.job_profiles,
        element: <JobProfilesPage />,
      },
      {
        path: RouterPath.analytics,
        element: <AnalyticsPage />,
        children: [
          {
            index: true,
            element: <Navigate to={getAvailableAnalyticTabs()[0]?.route || ''} replace={true} />,
          },
          {
            path: RouterPath.peps,
            element: <PepAnalyticsPage />,
          },
          {
            path: RouterPath.tasks,
            element: <TaskAnalyticsPage />,
          },
          {
            path: RouterPath.healthchecks,
            element: <HCAnalyticsPage />,
          },
          {
            path: RouterPath.onboardings,
            element: <OnboardingsAnalyticsPage />,
          },
        ],
      },
      {
        path: RouterPath.notifications,
        element: <ProfileNotificationsPage />,
      },
      {
        path: RouterPath.feedback,
        element: <FeedbackPage />,
      },
    ],
  },
  {
    element: (
      <PluginsProvider>
        <SimpleLayout />
      </PluginsProvider>
    ),
    children: [
      {
        path: RouterPath.login,
        element: <LoginPage />,
      },
      {
        path: RouterPath.not_found,
        element: <ErrorPage />,
      },
    ],
  },
]);
