import Title from '@library/ui/title/Title';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';



type IProps = {
  //
}

const ProfilePage: FC<IProps> = () => {
  return (
    <>
      <Title size="h2">Профиль</Title>
    </>
  );
};

export default observer(ProfilePage);
