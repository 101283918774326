import React, { type FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  emptyPlaceHolderPlugin,
  labelFormatter,
  prcntTicks,
  prcntTooltip,
} from '../../model/helpers/formatters';
import { DepartmentStats } from '../../model/types/pep.types';

interface IProps {
  departmentStats: DepartmentStats[]
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '% ИПР по форматам',
    },
    tooltip: prcntTooltip,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: prcntTicks,
    },
  },
};

const PepTypePcntChart: FC<IProps> = ({ departmentStats }) => {
  const stats = useMemo(() => {
    const stats = departmentStats.reduce(
      (acc, x) => {
        const all = x.fileTypeConfluence + x.fileTypeLms + x.fileTypeOffice;

        if (all !== 0) {
          acc.officePcnt.push((100 * x.fileTypeOffice) / all);
          acc.lmsPcnt.push((100 * x.fileTypeLms) / all);
          acc.confluencePcnt.push((100 * x.fileTypeConfluence) / all);
        } else {
          acc.officePcnt.push(0);
          acc.lmsPcnt.push(0);
          acc.confluencePcnt.push(0);
        }

        return acc;
      },
      {
        labels: departmentStats.map(labelFormatter),
        officePcnt: new Array<number>(),
        lmsPcnt: new Array<number>(),
        confluencePcnt: new Array<number>(),
      },
    );

    return stats;
  }, [departmentStats]);

  const data = {
    labels: stats.labels,
    datasets: [
      {
        label: 'Confluence',
        data: stats.confluencePcnt,
        backgroundColor: '#2170FA',
        barThickness: 40,
      },
      {
        label: 'LMS',
        data: stats.lmsPcnt,
        backgroundColor: '#FFCD1C',
        barThickness: 40,
      },
      {
        label: 'Office',
        data: stats.officePcnt,
        backgroundColor: '#59E500',
        barThickness: 40,
      },
    ],
  };

  return <Bar options={options} data={data} plugins={[...emptyPlaceHolderPlugin]} />;
};

export default PepTypePcntChart;
