
import { Button, ButtonSet, Column, Row, Toggle } from '@carbon/react';
import { $catalog, $loader } from '@library/providers/StoreProvider';
import Title from '@library/ui/title/Title';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import { formErrors, formMessages } from '@library/utils/constants';
import { showErrorAlert, showSuccessAlert } from '@library/utils/toast';
import { notificationsService } from '@services';
import { Channel, Setting } from '@services/models/notifications';
import { useLoader } from '@src/library/utils/hooks';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import styles from './ProfileNotificationsPage.module.scss';

type IProps = {
  //
}

const ProfileNotificationsPage: FC<IProps> = () => {
  const notificationTypesList = useMemo(() => {
    return $catalog.notificationTypes.map((x) => ({
      notificationType: x.value,
      label: x.shortName,
      channels: _.reduce(
        $catalog.notificationChannels,
        (acc: any, item) => {
          acc[item.value] =
            x.value === 'TaskOverdued' && ['Mail', 'HCM'].includes(item.value) ? 1 : 0;

          return acc;
        },
        {},
      ),
    }));
  }, [$catalog.notificationTypes, $catalog.notificationChannels]);

  const defaultValues = {
    notifications: notificationTypesList.map((x) => _.pick(x, ['notificationType', 'channels'])),
  };
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const isLoading = useLoader(async () => {
    const response = await notificationsService.fetch();

    if (response.isSuccess && response.data?.data) {
      const preparedSettings = defaultValues.notifications.map((item: any) => {
        const found = response.data.data.find(
          (x: Setting) => x.notificationType === item.notificationType,
        ) as Setting;

        if (found) {
          const channels = found.channels.reduce((acc: any, curr: Channel) => {
            acc[curr.channel] = _.toNumber(curr.value);

            return acc;
          }, {});

          return { ...item, channels };
        }

        return item;
      });

      reset({ notifications: preparedSettings });
    }
  });

  /*
   * Prepared values
   */
  const values = watch();

  // useEffect(() => {
  //   if (!isLoading) {
  //     console.log('values', values)
  //     console.log('errors', errors)
  //   }
  // }, [isLoading, values])

  function RenderToggle({
    type,
    channel,
    value,
    index,
  }: {
    type: string
    channel: string
    value: number
    index: number
  }) {
    return (
      <Toggle
        id={[type, channel, index].join('_')}
        labelA=""
        labelB=""
        defaultToggled={!!value}
        disabled={type === 'TaskOverdued' && ['Mail', 'HCM'].includes(channel)}
        onToggle={(val: boolean) => {
          setValue(`notifications.${index}.channels.${channel}`, val ? 1 : 0);
        }}
      />
    );
  }

  const loaderName = 'notifications-update';
  const onSubmit = $loader.registerHandler(loaderName, async (data) => {
    let payload = data.notifications.map((x: any) => ({
      ...x,
      channels: Object.entries(x.channels).map(([channel, value]) => ({ channel, value })),
    }));

    const response = await notificationsService.update({ data: payload });

    if (response.isSuccess) {
      showSuccessAlert(formMessages.notificationsUpdated);
    } else {
      showErrorAlert(formErrors.somethingWentWrong);
    }
  });

  return (
    <>
      <Title size="h2">Настройки уведомлений</Title>
      <WithLoaderWrapper isLoading={isLoading}>
        <div className={styles.tableWrapper}>
          <form id={loaderName} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Column lg={9}>
                <Row className={classNames(styles.row, styles.rowHeader)}>
                  <Column lg={7} className={styles.column} />
                  {$catalog.notificationChannels.map((channel) => (
                    <Column key={channel.value} className={styles.column}>
                      {channel.shortName}
                    </Column>
                  ))}
                </Row>
                {notificationTypesList.map((item, index) => (
                  <Row key={item.notificationType} className={styles.row}>
                    <Column lg={7} className={styles.column}>
                      {item.label}
                    </Column>
                    {$catalog.notificationChannels.map((channel) => (
                      <Column key={channel.value} className={styles.column}>
                        <RenderToggle
                          type={item.notificationType}
                          channel={channel.value}
                          value={values.notifications[index].channels[channel.value]}
                          index={index}
                        />
                      </Column>
                    ))}
                  </Row>
                ))}
              </Column>
            </Row>
          </form>
        </div>
        <ButtonSet className="mt-20">
          <Button form={loaderName} type="submit" disabled={$loader.isRunHandler(loaderName)}>
            {$loader.isRunHandler(loaderName) ? 'Загрузка...' : 'Сохранить'}
          </Button>
        </ButtonSet>
      </WithLoaderWrapper>
    </>
  );
};

export default observer(ProfileNotificationsPage);
