import { $catalog } from '@library/providers/StoreProvider';
import { ICatalogTaskStatus } from '@src/modules/catalog/model/catalogStore';
import { toJS } from 'mobx';
import { useMemo, useState } from 'react';



export const useStatusFilter = () => {
  const optionsStatus = useMemo(() => toJS($catalog.taskStatus), []);
  const [statuses, setStatuses] = useState<ICatalogTaskStatus[]>(optionsStatus);

  return {
    optionsStatus,
    statuses,
    setStatuses,
  };
};
