export function asTree(departmentTable: any[]) {
  const departmentIdx = new Map<string, number>();
  let departments = departmentTable.map((x) => ({ ...x })), //make shallow copy
    node,
    roots = [],
    i;

  for (i = 0; i < departmentTable.length; i += 1) {
    const key: string = departmentTable[i].code;
    departmentIdx.set(key, i);
  }

  for (i = 0; i < departments.length; i += 1) {
    node = departments[i];
    const parent: string | undefined = node.parent;
    const parentIdx = departmentIdx.get(parent!);

    if (parent && parentIdx !== undefined) {
      // if you have dangling branches check that map[node.parentId] exists
      if (departments[parentIdx].children === undefined) {
        departments[parentIdx].children = [];
      }

      departments[parentIdx].children!.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
}
