
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react';
import LayoutLkPageWrapper from '@library/layouts/LayoutLkPageWrapper';
import { useStore } from '@src/library/providers/StoreProvider';
import React from 'react';

const EquipmentPage = () => {
  const { $cabinet } = useStore();
  const equipmentList = $cabinet.data?.equipment?.data;

  const headers = [
    { key: 'id', header: '№' },
    { key: 'title', header: 'Наименование' },
  ];

  const rows = _.orderBy(equipmentList, 'startDate', 'asc').map((equipment, index) => ({
    id: (index + 1).toString(),
    title: equipment.name,
  }));

  return (
    <LayoutLkPageWrapper isLoading={false}>
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => {
          return (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })} onClick={() => {}}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow {...getRowProps({ row })} onClick={() => {}}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        }}
      </DataTable>
    </LayoutLkPageWrapper>
  );
};

export default EquipmentPage;
